.inforeg {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    align-items: center;

}
.textreg {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: initial;
    width: 366px;

}
.center {
    text-align: center;
    font-family: 'Montserrat Bold', sans-serif;
}
.center h1 {
    font-size: 27px
}
.agreements {

    width: 75%;
    align-items: start;
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 10px;
}

.forgetreg {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 10px;

}

.helppasswordreg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 12px;
    width: 100%;
    gap: 65px
}

.loginreg {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
}

.loginregagreements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    font-size: 10px;
    width: 98%;

}

/* Стили для инпута и лейбла */
.loginregagreements input[type="checkbox"] {
    display: none;
}

.loginregagreements input[type="checkbox"] + label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    text-align: start;
}

/* Стили для круглой области */
.loginregagreements input[type="checkbox"] + label::before {
    content: "";
    width: 15px; /* Размер круглой области */
    height: 15px; /* Размер круглой области */
    border-radius: 50%; /* Превращаем в круг */
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    margin-right: 10px; /* Расстояние между круглой областью и текстом */
    margin-top: 5px;
}

/* Стили для галочки при отмеченном чекбоксе */
.loginregagreements input[type="checkbox"]:checked + label::before {

    content: "\2713"; /* Юникод-символ галочки */
    text-align: center;
    font-size: 12px; /* Размер символа */
    line-height: 15px; /* Выравнивание символа по вертикали */
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
}

.loginregagreements .agreements {
    flex: 1; /* Занимает оставшееся пространство, чтобы текст занимал всю доступную ширину */
    font-size: 12px;
}
.isAgreed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    font-size: 10px;
    width: 98%;

}

/* Стили для инпута и лейбла */
.isAgreed input[type="checkbox"] {
    display: none;
}

.isAgreed input[type="checkbox"] + label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    text-align: start;
}

/* Стили для круглой области */
.isAgreed input[type="checkbox"] + label::before {
    content: "";
    width: 15px; /* Размер круглой области */
    height: 15px; /* Размер круглой области */
    border-radius: 50%; /* Превращаем в круг */
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    margin-right: 10px; /* Расстояние между круглой областью и текстом */
    margin-top: 5px;
}

/* Стили для галочки при отмеченном чекбоксе */
.isAgreed input[type="checkbox"]:checked + label::before {

    content: "\2713"; /* Юникод-символ галочки */
    text-align: center;
    font-size: 12px; /* Размер символа */
    line-height: 15px; /* Выравнивание символа по вертикали */
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
}

.isAgreed .agreements {
    flex: 1; /* Занимает оставшееся пространство, чтобы текст занимал всю доступную ширину */
    font-size: 12px;
}
.landing-modal-form-message1 {
    font-size: 18px;
    font-weight: 400;
margin-left: 20px;
    width: 340px;
    text-align: start;
}
.landing-modal-form-message1.success {
    color: green;
}
.landing-modal-form-message1.error {
    color: red;
}
.loginreg input[type="text"] {
    border: none;
    border-radius: 999px;
    padding: 13px;
    width: 340px;
    background: #E8E6FF;
    text-align: start;
    font-family: 'Montserrat Regular', sans-serif;
}

.loginreg input[type="password"] {
    border: none;
    border-radius: 999px;
    padding: 13px;
    width: 340px;
    background: #E8E6FF;
    text-align: start;
    font-family: 'Montserrat Regular', sans-serif;
}

.formloginreg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    align-items: center;
}
.buttonsubmitreg{
    text-align: center;
    margin-top: 15px;
}
/*.buttonsubmitreg button {*/
/*    border: none;*/
/*    border-radius: 41px;*/
/*    padding: 5px 25px;*/
/*    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);*/
/*    color: white;*/
/*    cursor: pointer;*/
/*    font-size: 20px;*/
/*    flex-flow: wrap;*/
/*    justify-content: center;*/
/*    white-space: nowrap;*/
/*    font-family: 'Montserrat SemiBold', sans-serif;*/
/*    flex-flow: wrap;*/
/*}*/

/*.buttonsubmitreg button:hover {*/
/*    background: #ff9000;*/
/*    transition: 1.5s;*/
/*}*/

.buttonsubmitreg input {
    border: none;
    border-radius: 41px;
    padding: 5px 25px;
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    color: white;
    cursor: pointer;
    font-size: 20px;
    flex-flow: wrap;
    justify-content: center;
    white-space: nowrap;
    font-family: 'Montserrat SemiBold', sans-serif;
    flex-flow: wrap;
}

.buttonsubmitreg input:hover {
    background: #ff9000;
    transition: 1.5s;
}

.loginregagreements input[type="checkbox"] + label {
    padding-left: 20px;
}
@media (max-width: 600px) {
    .center {
        text-align: center;
    }
    .loginreg, .loginregagreements {
        margin: 10px; /* Уменьшаем отступы для мобильных устройств */
    }
    .center h1 {
        font-size: 20px;
    }
    .agreements {

        font-size: 14px; /* Уменьшаем размер шрифта */
    }

    .helppasswordreg {
        gap: 10px; /* Уменьшаем горизонтальный отступ между элементами */
        font-size: 8px; /* Уменьшаем размер шрифта */
        margin: 10px;
    }

    .forgetreg {
        font-size: 8px; /* Уменьшаем размер шрифта */
    }

    .loginreg input[type="text"],
    .loginreg input[type="password"] {
        text-align: left; /* Левое выравнивание текста */
         font-size: 10px; /* Уменьшаем размер шрифта */
        padding: 10px; /* Уменьшаем внутренний отступ */
        width: 135px;

    }


    .loginregagreements .agreements {
        flex: 1 1;
        font-size: 10px;
    }
    .buttonsubmitreg {
        text-align: center;
    }
    .loginregagreements input[type="checkbox"] + label {

        font-size: 10px;

    }

    .textreg {

        width: 50%;

    }

    .loginregagreements input[type="checkbox"] + label {
        padding-left: 10px;
    }

    .buttonsubmitreg button {

        font-size: 15px;

    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    .center {
        text-align: center;
    }
    .loginreg input[type="text"], .loginreg input[type="password"] {
        text-align: left;
        font-size: 6px;
        padding: 6px;
    }

    .formloginreg {
        justify-content: center;

        margin-top: 0;
        margin-bottom: 0;
        margin-right: 20px;
        margin-left: 20px;
    }

    .formloginreg h2 {
        font-size: 10px;
    }
    .buttonsubmitreg {
        text-align: center;
    }


    .helppasswordreg {
        gap: 10px; /* Уменьшаем горизонтальный отступ между элементами */
        font-size: 8px; /* Уменьшаем размер шрифта */
        margin: 0;
    }

    .loginreg {
        display: flex;
        margin-top: 10px;
        margin-bottom: 5px;

    }
}