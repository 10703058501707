/* Common */
.landing-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 54px 40px;
}
.landing-modal-cross {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
    width: 40px;
    height: 40px;
    opacity: 0.5;
}
.landing-modal-cross:hover {
    opacity: 1;
}
.landing-modal-header {
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
}
.landing-modal-header > span {
    border-bottom: 1px solid #000;
}
.landing-modal-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 16px;
    font-weight: 400;
}
.landing-modal-form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.landing-modal-form-note {
    font-style: italic;
}
.landing-modal-form-message {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    width: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}
.landing-modal-form-message.success {
    color: green;
}
.landing-modal-form-message.error {
    color: red;
}
.landing-modal-form-input {
    width: 100%;
    height: 48px;
    line-height: 1.6;
    font-size: 18px;
    font-weight: 400;
    box-shadow: 0 1px 0 0 #000;
    border: none;
    outline: none;
}
.landing-modal-form-checkbox > label {
    display: flex;
    align-items: flex-start;
    gap: 4px;
}
.landing-modal-form-checkbox input,
.landing-modal-form-checkbox label {
    cursor: pointer;
}
.landing-modal-form-controls {
    display: flex;
    justify-content: flex-end;
    font-size: 32px;
    font-weight: 400;
}
.landing-modal-form-controls > input {
    margin-bottom: 0;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
}
.landing-modal-form-controls > input:disabled {
    cursor: not-allowed;
    color: rgba(16, 16, 16, 0.3);
}
.landing-modal-login-kundelik {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    width: 100%;
    padding: 8px 12px;
    background-color: gainsboro;
    border-radius: 12px;
    font-family: sans-serif;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.8;
}
.landing-modal-login-kundelik:hover {
    opacity: 1;
}
.landing-modal-login-kundelik > img {
    width: 96px;
}

/* Authentication (login/forgot password) */
.landing-modal-form-toggle-login > span {
    cursor: pointer;
}
.landing-modal-form-toggle-login > span {
    color: #324f7e;
}
.landing-modal-form-toggle-login > span:hover {
    border-bottom: 1px solid #324f7e;
}

/* Authentication (registration) */

/* Other modals */
.landing-modal-about.text,
.landing-modal-manual.text {
    line-height: 24px;
    font-size: 16px;
    text-align: justify;
}
.landing-modal-terms {
    text-align: justify;
}
.landing-modal-about.contacts__list {
    display: flex;
    justify-content: center;
    gap: 24px;
    padding: 0;
    margin: 0;
    list-style: none;
}
.landing-modal-about.contacts__item {
    line-height: 1;
    font-size: 18px;
}
.landing-modal-manual ol {
    margin-top: 0;
    padding-left: 2rem;
}

@media only screen and (max-width: 480px) {
    .landing-modal {
        padding: 40px 32px;
    }
    .landing-modal-cross {
        right: 24px;
        top: 24px;
        width: 32px;
        height: 32px;
    }
    .landing-modal-header {
        font-size: 28px;
        line-height: 28px;
    }
    .landing-modal-form-input {
        height: 36px;
        line-height: 18px;
        font-size: 16px;
    }
    .landing-modal-form-controls {
        font-size: 24px;
    }
}

@media only screen and (max-width: 400px) {
    .landing-modal {
        padding: 36px 24px;
        font-size: 14px;
    }
    .landing-modal-cross {
        right: 20px;
        top: 20px;
        width: 24px;
        height: 24px;
    }
    .landing-modal-header {
        font-size: 22px;
        line-height: 22px;
    }
    .landing-modal-form-input {
        height: 32px;
        font-size: 14px;
        line-height: 14px;
    }
    .landing-modal-form-controls {
        font-size: 20px;
    }
    .landing-modal-about .contacts__list {
        gap: 16px;
    }
    .landing-modal-about .contacts__item {
        font-size: 14px;
    }
}