.custom-modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    border: none;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #3C3C56;
    padding: 20px;
    color: #FFFFFF;
    border: none;
    border-radius: 50px;
    width: auto; /* Ограничиваем максимальную ширину контейнера */
    max-width: 850px;
    overflow-y: auto; /* Добавляем полосы прокрутки при необходимости */
    max-height: 90%;
    scrollbar-width: none; /* Скрываем полосу прокрутки для Firefox */
    -ms-overflow-style: none; /* Скрываем полосу прокрутки для IE/Edge */
}
.modal-content::-webkit-scrollbar {
    display: none; /* Скрываем полосу прокрутки для WebKit (Chrome, Safari) */
}
.close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-size: 24px;
}

.close img {
    width: 40px;
}

@media (max-width: 768px) {
    .modal-content {
        width: 70%; /* Уменьшим ширину для мобильных экранов */
        padding: 10px; /* Уменьшим отступы */
    }

    .close {
        font-size: 20px; /* Уменьшим размер кнопки закрытия */
        top: 15px;
        right: 15px;
    }
    .close img {
        width: 30px;
    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    .modal-content {
        width: 53%;; /* Уменьшим ширину для мобильных экранов */
        padding: 10px; /* Уменьшим отступы */
        max-height: 80%;
    }
    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #3C3C56;
        padding: 20px;
        color: #FFFFFF;
        border: none;
        border-radius: 20px;
        width: 53%; /* Ограничиваем максимальную ширину контейнера */
        overflow: auto; /* Добавляем полосы прокрутки при необходимости */
        max-height: 90%;
    }
    .close img {
        width: 30px;
    }
}