/* @import '../animate.css'; */
.Footer {
        bottom: 0;
        left: 0;
    display: flex;
    width: 100%;
}
/* Для блока слева */
.animate__slideInLeft {
    animation-duration: 1s; /* Длительность анимации */
    animation-delay: 0.5s; /* Задержка перед началом анимации */
}

/* Для блока справа */
.animate__slideInRight {
    animation-duration: 1s; /* Длительность анимации */
    animation-delay: 0.5s; /* Задержка перед началом анимации */
}

.WelcomePage {
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;



}
.Info {
    display: flex;
    margin-top: 1%;
    overflow: hidden; /* Скрывает содержимое, выходящее за пределы контейнера */
    flex: 1;
    justify-content: center;
    align-items: center;

}

.Info-button {
    display: flex;
    justify-content: space-between;
}

.Info-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    padding: 0 0 0 5%; /* Здесь установите отступ слева на 100px */
}

.Info-right {
    flex: 1;
    display: flex;
    align-items: center;
    width: 50%;
margin-left: -35%;

}

.Info-text {
    color: white;
    font-size: 45px;
    font-weight: 700;
    font-family: 'Montserrat ExtraBold', sans-serif;
    width: 60%;
    line-height: 1;
}


.registration {
    margin-top: 25px;
    width: 60%;
    height: 100%;
}

.come {
    padding: 5px 25px;
    border-radius: 41px;
    gap: 10px;
    color: #00B9F8;
    cursor: pointer;
    border: none;
    margin-right: 25px;
    font-family: 'Montserrat SemiBold', sans-serif;
    font-size: 20px;
    flex-flow: wrap;
    justify-content: center;
    white-space: nowrap;
}


.hovered {
    background-color: #ff9000!important;
}
.Become-a-member {
    padding: 5px 35px;
    border-radius: 41px;
    gap: 10px;
    cursor: pointer;
    border: none;
    background: linear-gradient(90.09deg, #7359EA -1.33%, #AE61FF 41.19%, #7359EA 99.93%);
    color: white;
    font-family: 'Montserrat SemiBold', sans-serif;
    font-size: 20px;
    flex-flow: wrap;
    justify-content: center;
    white-space: nowrap;
}

.Become-a-member:hover {
    background: #ff9000;
    transition: 1.5s;
}






.Info-button {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #3C3C56;
    color: white;
}

.Company-name {
    color: gray;
}

.Untitled img {
    width: 150%;
    max-width: 1250px;
}

.Untitled {
    width: 80%;
    display: contents;
    z-index: 1;
    flex-direction: column;

}

.mobpkimg {
    display: none;
}

@media  (min-width: 2000px) {

    .Info-text {
        font-size: 60px;
    }

}
@media (max-width: 1024px) {
    .Info {
        margin-top: 80px;
    }
    .registration {

        width: 60%;

    }
}

@media (max-width: 1245px) {
    .Info {
        margin-top: 40px;
    }
}
@media (max-width: 850px) {
    .WelcomePage {
        display: flex;
        flex-direction: inherit;
        justify-content: space-between;
    }
    .Info {
        flex-direction: column; /* Отображать блоки вертикально */
        align-items: center;
        margin: 80px 20px 20px 20px;
    }

    .Info-left {
        width: 100%; /* Один блок занимает всю ширину */
        display: block;

    }
    .Untitled {
        width: 100%;
    }
    .Company-name {
        font-size: 10px;
    }
    .Info-right {
        width: 100%; /* Один блок занимает всю ширину */
        margin-left: 0;
    }

    .Info-button {
        flex-direction: column;
        padding: 10px;

    }

    .come {
        padding: 5px 25px;
        margin: 10px;
        font-family: 'Montserrat SemiBold', sans-serif;
        font-size: 3vw;
    }

    .Become-a-member {
        padding: 5px 35px;
        margin: 10px;
        font-family: 'Montserrat SemiBold', sans-serif;
        font-size: 3vw;
    }


    .Info-text {
        color: white;
        font-size: 20px;
        font-weight: 700;
        width: 100%;

    }

    .registration {
        display: flex;
        width: 100%;
    }

    .Untitled img {
        width: 100%;
    }

    .mobpkimg {
        display: block;
    }

    .fullpkimg {
        display: none;
    }
}
@media (max-width: 768px) and (orientation: landscape) {
    .Info {
        display: flex;
        justify-content: space-between;
        overflow: hidden; /* Скрывает содержимое, выходящее за пределы контейнера */
        flex-direction: row;
    }

    .Untitled {
        width: 100%;
    }

    .Info-left {
        width: 50%;
        padding: 0 20px;
    }

    .Info-right {
        width: 50%;
        margin-left: 0;
    }

    .Info-button {
        flex-direction: column;
        padding: 10px;
    }

    .come {
        width: 30%;
        margin: 10px;
        font-size: 10px;
    }

    .Become-a-member {
        width: 40%;
        margin: 10px;
        font-size: 10px;
    }


    .Info-text {
        color: white;
        font-size: 15px;
        font-weight: 700;

    }

    .registration {
        display: flex;
    }

    .Untitled img {
        width: 100%;
    }

    .mobpkimg {
        display: block;
    }

    .fullpkimg {
        display: none;
    }
}
@media (max-width: 760px) and (orientation: landscape) {
    .Info {
        display: flex;
        justify-content: space-between;
        overflow: hidden; /* Скрывает содержимое, выходящее за пределы контейнера */
        flex-direction: row;
    }

    .Untitled {
        width: 100%;
    }

    .Info-left {
        width: 50%;
        padding: 0 20px;
    }

    .Info-right {
        width: 50%;
        margin-left: 0;
    }

    .Info-button {
        flex-direction: column;
        padding: 10px;
    }

    .come {
        width: 30%;
        margin: 10px;
        font-size: 10px;
    }

    .Become-a-member {
        width: 40%;
        margin: 10px;
        font-size: 10px;
    }


    .Info-text {
        color: white;
        font-size: 15px;
        font-weight: 700;

    }

    .registration {
        display: flex;
        width: 100%;
    }

    .Untitled img {
        width: 75%;
    }

    .mobpkimg {
        display: block;
    }

    .fullpkimg {
        display: none;
    }

}

