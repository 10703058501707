.formlogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
align-items: center;
    font-family: 'Montserrat Bold', sans-serif;
}
.formlogin h1 {
    font-size: 27px
}
.helppassword {
    align-items: center;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    text-align: initial;
    gap: 10%;
    width: 366px;
}

.Show {
    margin-right: 20px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
}
.showPassword {
    font-family: 'Montserrat Regular', sans-serif;

}

.forget {
    text-decoration: none;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 12px;
}

:hover.forget {
    color: #4d4d4d;
}

.buttonsubmit {
    margin-top: 20px;
}

.buttonsubmit button {
    border: none;
    border-radius: 41px;
    padding: 5px 25px;
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    color: white;
    cursor: pointer;
    font-size: 20px;
    flex-flow: wrap;
    justify-content: center;
    white-space: nowrap;
    font-family: 'Montserrat SemiBold', sans-serif;
    flex-flow: wrap;
}

.buttonsubmit button:hover {
    background: #ff9000;
    transition: 1.5s;
}

.label-for-showPassword {
    display: inline-block;
    width: 30px; /* Задайте желаемую ширину и высоту для создания круглой формы */
    height: 30px;
    border-radius: 50%; /* Делаем круглой */
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    color: white; /* Цвет текста */
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

input[type="checkbox"] {
    display: none;
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
}

input[type="checkbox"] + label {
    position: relative;
    padding-left: 20px; /* Размер круглой области */
    cursor: pointer;
    padding-top: 2px;
    font-family: 'Montserrat Regular', sans-serif;
    touch-action: manipulation;
    font-size: 12px;
}
input[type="text"], input[type="password"] {
    border: none;
    border-radius: 999px;
    padding: 13px;
    width: 313px;
    background: #E8E6FF;
    text-align: start;
    font-family: 'Montserrat Regular', sans-serif;
    outline: none;
}

input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 2px;
    width: 15px;
    height: 15px;
    border-radius: 50%; /* Превращаем в круг */
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
}

/* Отображаем галочку при отмеченном чекбоксе */
input[type="checkbox"]:checked + label::before {
    content: "\2713"; /* Юникод-символ галочки */
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;

}
.login, .password {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    background: #E8E6FF;
    border-radius: 999px;
}
.logimg {
    padding-left: 10px;
}

.login input {

    background-position: 10px center;
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
    padding-left: 10px;

}


.password input[type="password"] {

    background-position: 10px center;
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;

}

.password input[type="text"] {

    background-position: 10px center;

    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
}
.submit {
    font-family: 'Montserrat Bold', sans-serif;
}

@media (max-width: 600px) {
    .formlogin {
        margin: 15px;
    }

    .login, .password {
        margin: 10px;
    }

    input[type="text"], input[type="password"] {
        width: 135px;
        touch-action: manipulation;
        height: 0;
    }
    .password input[type="password"] {
        background-size: 10px;

    }
    .login input {

        background-size: 10px; /* Размер изображения */


    }
    .password input[type="text"] {
        background-size: 10px;
    }
    input[type="checkbox"] + label {

        padding-left: 10px;

        padding-top: 0;
    }

    .helppassword {

        width:135px;
    }

    .formlogin h1 {
        font-size:20px;
    }
    .label-for-showPassword {
        display: none;
    }

    input[type="checkbox"] + label {

        font-size: 10px;
    }
    .forget {

        font-size: 10px;;
    }
    .buttonsubmit button {

        font-size: 15px;

    }

    input[type="checkbox"] + label::before {

        left: -15px;

    }

}

@media (max-width: 1024px) and (orientation: landscape) {
    .buttonsubmit button {

        padding: 5px 30px;

    }

    .login, .password {
        margin: 5px;
    }

    input[type="text"], input[type="password"] {

        padding: 10px;
    }

    .formlogin {
        margin-top: 0;
        margin-bottom: 0;
    }
}