.instructions {
    text-align: left; /* Выравниваем текст по левому краю */
    margin: 20px; /* Добавляем небольшой отступ от краев */
    font-size: 14px;
}

.instructions iframe {
    aspect-ratio: 4 / 3;
}

.instructions h1 {
    text-align: center; /* Выравниваем заголовки по левому краю */
    margin: 10px 0; /* Отступы между заголовками */
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 27px;
}

.instructions h2 {
    text-align: left; /* Выравниваем заголовки по левому краю */
    margin: 10px 0; /* Отступы между заголовками */
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 20px;
}

.instructions span {
    display: block; /* Каждый span будет отображаться в новой строке */
    text-align: left; /* Выравниваем текст по левому краю */
    margin: 5px 0; /* Отступы между строками */
    font-family: 'Montserrat SemiBold', sans-serif;
}

.instructions .buttonsubmit {
    margin: 20px 0; /* Добавляем вертикальный отступ сверху и снизу */
    display: flex;
    justify-content: center; /* Выравниваем кнопку по горизонтали по центру */
}

.instr {
    margin-top: 20px;
}

@media (max-width: 600px) {
    .instructions {
        text-align: left;
        margin: 10px; /* Уменьшим отступы */
        font-size: 12px; /* Уменьшим размер шрифта */
    }

    .instructions h1{
        font-size: 20px;
    }
    .instructions h2 {
       font-size: 15px;
    }

    .instructions span {
        margin: 3px 0; /* Уменьшим отступы между строками */
    }

    .instructions .buttonsubmit {
        margin: 10px 0; /* Уменьшим вертикальный отступ */
    }

    .instr {
        margin-top: 10px; /* Уменьшим верхний отступ для .instr */
    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    .instructions {
        text-align: left;
        margin: 10px; /* Уменьшим отступы */
        font-size: 2vh;
    }

    .instructions span {
        display: block; /* Каждый span будет отображаться в новой строке */
        text-align: left; /* Выравниваем текст по левому краю */
        margin: 0px 0; /* Отступы между строками */
    }
    .instr {
        margin-top: 2px;
    }
}
