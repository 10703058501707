
.wrg-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.wrg-toggle-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.wrg-toggle-check{
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;

    transition: opacity .25s ease;

}
.wrg-toggle-uncheck {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;

    transition: opacity .25s ease;
}
.wrg-toggle-check {
    left: 8px;
}
.wrg-toggle-uncheck {
    opacity: 1;
    right: 6px;
}

.wrg-toggle-uncheck span{
    align-items: center;
    display: flex;
    height: 10px;
    justify-content: center;
    position: relative;
    width: 10px;
}
.wrg-toggle-check span {
    align-items: center;
    display: flex;
    height: 10px;
    justify-content: center;
    position: relative;
    width: 10px;
}
.wrg-toggle--checked .wrg-toggle-container  {
    background-color: #00B9F8 !important;
}
.wrg-toggle .wrg-toggle-container {
    background-color: #ffffff; /* Цвет фона при выключенной теме */
}
.wrg-toggle .wrg-toggle-circle {
    background-color: #3C3C56; /* Цвет фона при выключенной теме */
}
.wrg-toggle--checked .wrg-toggle-circle  {
    background-color: #ffffff !important;
}

.wrg-toggle-container {
    position: fixed;
    width: 51px;
    height: 25px;
    padding: 0;
    border-radius: 30px;
    transition: all .2s ease;
    z-index: 1;
    right: 20px;
    bottom: 20px;
}


.wrg-toggle-circle {
    transition: all 1s cubic-bezier(.23,1,.32,1) 0ms;
    position: relative; /* Изменено на relative */
    top: 1px;
    left: 1px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    z-index: 1;
    box-sizing: border-box;
    overflow: hidden; /* Добавлено, чтобы скрыть излишки фото */
}


.wrg-toggle img {

    height: 16px; /* Установите желаемую высоту */
    position: absolute;
    z-index: 2;
}

.wrg-toggle--checked .wrg-toggle-check{
     opacity: 1;
 }

.wrg-toggle--checked .wrg-toggle-circle {
    left: 27px;
}


@media (max-width: 1024px) and (orientation: landscape) {
    .wrg-toggle-container {
        left: 90%;
        top: 91%
    }
}