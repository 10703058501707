.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    width: 100%;

    position: fixed;
    z-index: 9999;
}
.navigcia h3 {
    font-family: 'Montserrat SemiBold', sans-serif;
    margin: 1rem 0;
}

.logoimg img {
    width: 135px;
    cursor: pointer;
}

.logo img {
    width: 100%;
    margin-left: 60px;
}

.logoimg {
    margin-left: 40px;
}

.navigcia-first {
    list-style: none;
    cursor: pointer;
}

.navigcia-first:hover {
    color: gray;
}

.navigcia-centr {
    list-style: none;
    cursor: pointer;
}

.navigcia-centr:hover {
    color: gray;
}

.navigcia-last {
    list-style: none;
    cursor: pointer;
}

.navigcia-last:hover {
    color: gray;
}

.navigcia li {
    display: inline-block;
    margin-right: 20px;
    font-size: 20px


}

.nav-and-lang {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 20px;

}
/* Для Polygon1.svg */
.langselect[data-background="Polygon1"] {
    background-image: url('../../../assets/images/landing/Polygon\ 1.svg');
    background-size: 10px; /* Измените размер на подходящий вам */
}

/* Для Polygon2.svg */
.langselect[data-background="Polygon2"] {
    background-image: url('../../../assets/images/landing/треугольник.svg');
    background-size: 10px; /* Измените размер на подходящий вам */
}

/* Остальные стили вашего .langselect */

.langselect {
    -webkit-appearance: none;
    -moz-appearance: none;

    position: relative;
    margin-right: 30px;
    height: 28px;
    width: 120px;
    font-size: 17px;
    border-radius: 5px ;
    background-color: #00B9F8;
    color: white;
    border: none;
    appearance: none; /* Скрываем стандартные стили селекта */
    background-repeat: no-repeat; /* Отключаем повторение фонового изображения */
    background-position: right 10px center; /* Выравнивание изображения справа по центру */
    padding-right: 20px; /* Добавляем отступ справа для размещения изображения */
    outline: none;
    padding-left: 5px;
    font-family: 'Montserrat SemiBold', sans-serif;
}

.langselect option {
    background-color: white;
    color: black;
    font-family: 'Montserrat Regular', sans-serif;
    padding: 5px 10px; /* Задаем внутренний отступ для опций */
    border-radius: 0!important;
}








.mobile-menu {
    display: none; /* По умолчанию скрыть мобильное меню */

}

.mobile-menu-button {
    display: none; /* По умолчанию скрыть кнопку на больших экранах */

}

.togl-mob {
    display: none;
}




@media (min-width: 2000px) {
    .langselect {
        margin-right: 30px;
        height: 40px;
        width: 140px;
        font-size: 22px;
    }
    .logoimg img {
        width: 200%;
        cursor: pointer;
    }
}
@media (max-width: 1001px) {
    .navigcia-first-mob, .navigcia-centr-mob, .navigcia-last-mob {
        font-family: 'Montserrat SemiBold', sans-serif;

    }

    .Header {

        width: 100%;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .nav-and-lang {
        display: none;
        align-items: center;
        gap: 20px;

    }

    .togl-mob {
        display: block;
        margin-right: 50px;
        margin-top: 10px;
    }

    .langselect-mob {
        height: 28px;
        width: 120px;
        font-size: 15px;
        border-radius: 5px; /* Normal border radius */
        background-color: #00B9F8;
        color: white;
        border:none;
    }

    .langselect-mob option {
        background-color: white;
        color: black;
        border-radius: 0;
    }

    .logo img {
        width: 100px; /* Уменьшаем ширину логотипа */
        margin-left: 10px; /* Уменьшаем отступ слева */
    }

    .logoimg {
        margin-left: 10px; /* Уменьшаем отступ слева */
    }

    .navigcia li {
        display: block; /* Отображаем элементы списка в столбец */
        margin-right: 0; /* Убираем правый отступ */
    }

    .langselect {
        margin-right: 10px; /* Уменьшаем отступ справа */
        width: 100px; /* Уменьшаем ширину селекта */
        font-size: 14px; /* Уменьшаем размер шрифта */
    }

    /* Стили для кнопки гамбургера */
    .mobile-menu-button {
        display: block;
        cursor: pointer;
        font-size: 24px;
        position: absolute;
        top: 22px;
        right: 15px; /* Разместить кнопку в правом верхнем углу */
    }

    .navigcia-first-mob {
        list-style: none;
        cursor: pointer;
    }

    .navigcia-first-mob:hover {
        color: gray;
    }

    .navigcia-centr-mob {
        list-style: none;
        cursor: pointer;
    }

    .navigcia-centr-mob:hover {
        color: gray;
    }

    .navigcia-last-mob {
        list-style: none;
        cursor: pointer;
    }

    .navigcia-last-mob:hover {
        color: gray;
    }

    /* Стили для мобильного меню */
    .mobile-menu {
        display: block;
        background-color: #3C3C56; /* Цвет фона */
        position: absolute;
        top: 63px; /* Расположить меню под шапкой */
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        right: 0;
    }

    /* Стили для элементов в мобильном меню */
    .mobile-menu a {
        display: block;
        color: white;
        text-decoration: none;
        margin-bottom: 10px;
        font-size: 18px;
    }

    .nav-and-lang-mob {
        display: flex;
        flex-direction: column; /* Разместить элементы в столбце */
        align-items: flex-end; /* Выравнивать элементы справа */
    }

    /* Стили для блока меню */
    .menu-block {
        display: flex;
        flex-direction: column; /* Размещение элементов в столбце */
        align-items: flex-end; /* Выравнивание элементов справа */
        padding: 10px; /* Внутренний отступ блока */
        background-color: #3C3C56; /* Цвет фона блока */
    }

}