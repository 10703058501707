.contacts {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 100px;
    margin-right: 100px;
}

.titlecontact h1 {
    text-align: center; /* Выравниваем заголовок по центру */
    margin-bottom: 20px;
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 27px;
}


.conditions .buttonsubmit {
    margin: 20px 0; /* Добавляем вертикальный отступ сверху и снизу */
    display: flex;
    justify-content: center; /* Выравниваем кнопку по горизонтали по центру */
}

.contacts img {
    margin-right: 10px;
    vertical-align: middle; /* Выравнивание по вертикали посреди текста */
}


.contact-item h4 {
    font-size: 20px;
    margin-top: auto;
}

.contact-info {
    display: flex;
    justify-content: center; /* Выравнивание по центру по горизонтали */
}

.contact-item {
    display: flex;
    flex-direction: column; /* Устанавливаем направление контейнера в столбец */
    margin-bottom: 10px; /* Добавляем отступ между элементами .contact-item */
}

.cont {
    display: flex; /* Добавляем стиль flex для вложенных элементов */
    font-family: 'Montserrat SemiBold', sans-serif;
}
.cont:first-child {
    margin: 1rem 0;
}
.titlecontact {
    display: flex;
    justify-content: center;
    margin-bottom: -25px;
    font-family: 'Montserrat Bold', sans-serif;
}


@media (max-width: 600px) {

    .contacts h4 {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 14px;
        max-width: 80%;
    }

    .titlecontact h2 {

        margin-bottom: 20px;
        font-size: 15px;
        font-family: 'Montserrat Bold', sans-serif;
    }
    .titlecontact h1 {
        text-align: center;
        margin-bottom: 20px;
        font-family: 'Montserrat Bold', sans-serif;
        font-size: 20px;
    }
    .cont {
        display: flex;
        align-items: center;
        white-space: nowrap; /* Prevent text from wrapping */
        overflow: hidden; /* Hide overflowed text */
        text-overflow: ellipsis; /* Show ellipsis (...) for overflowed text */
        margin-bottom: -20px;
        font-family: 'Montserrat SemiBold', sans-serif;
    }


}
@media (max-width: 1024px) {
    .cont {
        display: flex;
        margin-left: 20px;
        margin-bottom: -10px;
        font-family: 'Montserrat SemiBold', sans-serif;
    }
}