.conditions {
    text-align: left; /* Выравниваем текст по левому краю */
    margin: 20px; /* Добавляем небольшой отступ от краев */
    font-size: 14px;
}

.conditions h1 {
    text-align: center; /* Выравниваем заголовки по левому краю */
    margin: 10px 0; /* Отступы между заголовками */
    width: 90%;
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 27px;
}

.conditions h2 {
    text-align: left; /* Выравниваем заголовки по левому краю */
    margin: 10px 0; /* Отступы между заголовками */
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 20px;
}

.conditions span {
    display: block; /* Каждый span будет отображаться в новой строке */
    text-align: left; /* Выравниваем текст по левому краю */
    margin: 5px 0; /* Отступы между строками */
    font-family: 'Montserrat SemiBold', sans-serif;
}

.instr {
    margin-top: 20px;
}

@media (max-width: 600px) {
    .conditions {
        text-align: center; /* Выравниваем текст по центру */
        margin: 10px; /* Уменьшаем отступ от краев */
        font-size: 12px; /* Уменьшаем размер шрифта */
    }
    .conditions h1 {

        font-size: 20px;
    }

    .conditions h2 {

        font-size: 15px;
    }


    .conditions span {
        text-align: center; /* Выравниваем текст по центру */
        margin: 4px 0; /* Уменьшаем отступы между строками */
    }

    .instr {
        margin-top: 10px; /* Уменьшаем верхний отступ для .instr */
    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    .conditions {
        font-size: 2vw; /* Уменьшаем размер шрифта */
        text-align: left;
    }

    .conditions span {
        display: block; /* Каждый span будет отображаться в новой строке */
        text-align: left; /* Выравниваем текст по левому краю */
        margin: -5px 0; /* Отступы между строками */
    }

}