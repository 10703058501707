html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;

}

.App {
  background-color: #3c3c56;
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  min-height: 100%;
  justify-content: center;

}

.wp {
  flex: 1;
}

.WelcomePage {
  flex: 1;
  overflow-y: auto; /* Добавьте скролл, если содержимое WelcomePage превышает высоту экрана */
}

.custom-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-loader::after , .custom-loader::before  {
  content: '';
  display: inline-block;
  width: 48px;
  height: 48px;
  background-color: #FFF;
  background-image:  radial-gradient(circle 14px, #0d161b 100%, transparent 0);
  background-repeat: no-repeat;
  border-radius: 50%;
  animation: eyeMove 10s infinite , blink 10s infinite;
}
@keyframes eyeMove {
  0%  , 10% {     background-position: 0 0}
  13%  , 40% {     background-position: -15px 0}
  43%  , 70% {     background-position: 15px 0}
  73%  , 90% {     background-position: 0 15px}
  93%  , 100% {     background-position: 0 0}
}
@keyframes blink {
  0%  , 10% , 12% , 20%, 22%, 40%, 42% , 60%, 62%,  70%, 72% , 90%, 92%, 98% , 100%
  { height: 48px}
  11% , 21% ,41% , 61% , 71% , 91% , 99%
  { height: 18px}
}