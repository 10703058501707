body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.admin-wrapper {
  padding: 52px 0 40px 0;
  min-height: 100vh;
}
.admin-header {
  position: fixed;
  overflow: hidden;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  background-color: #2d2d2a;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.7);
}
.admin-header nav {
  display: flex;
}
.admin-header-mobile {
  display: none;
}
.admin-header-mobile-button {
  display: none;
}
.admin-nav-link {
  cursor: pointer;
  padding: 16px 24px;
  color: #fffffa;
}
.admin-nav-link:hover {
  color: #95bddb;
}
.admin-nav-link-logout {
  margin-left: auto;
}
.admin-nav-link-active {
  background-color: #1a4f66;
}
.admin-content-wrapper {
  margin-top: 48px;

  & > .dashboard {
    margin-top: -64px;
  }
}
@media screen and (max-width: 800px) {
  .admin-wrapper {
    padding: 48px 16px;
  }
  .admin-header {
    display: none;
  }
  .admin-header-mobile {
    display: inherit;
  }
  .admin-header-mobile-button {
    position: fixed;
    z-index: 100;
    right: 24px;
    bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    cursor: pointer;
  }
  .admin-header-mobile-button img {
    width: 32px;
    height: 24px;
  }
  .admin-header-sidebar {
    width: 300px !important;
    background-color: #222222 !important;
    color: #fff;
  }
  .admin-nav-link {
    font-size: 16px;
  }
  .admin-content-wrapper {
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .admin-header-sidebar {
    width: 250px !important;
  }
  .admin-nav-link {
    font-size: 14px;
  }
  .admin-content-wrapper {
    margin-top: 0;
  }
}
@media screen and (max-width: 400px) {
  .admin-wrapper {
    padding: 40px 8px;
  }
  .admin-header-sidebar {
    width: 200px !important;
  }
  .admin-nav-link {
    font-size: 12px;
  }
  .admin-content-wrapper {
    margin-top: 0;
  }
}

.admin-analytics-subheader {
  margin: 2em auto;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.admin-analytics-class-subheader {
  margin-bottom: 1em;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.admin-analytics-search {
  margin: 2em auto;
}
.admin-analytics-class-search {
  margin-bottom: 2em;
}
@media screen and (max-width: 600px) {
  .admin-analytics-subheader {
    font-size: 16px;
  }
  .admin-analytics-class-subheader {
    font-size: 14px;
  }
}

.admin-authors-subheader {
  margin: 2em auto;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.admin-authors-new-author {
  margin: 2em 0 0.6em 0 !important;
  border-radius: 0px !important;
  background-color: #009b72 !important;
  color: #fff !important;
  font-size: 16px !important;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-authors-subheader {
    font-size: 16px;
  }
  .admin-authors-new-author {
    margin: 1em 0 0.4em 0 !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 400px) {
}

.admin-author-container {
  font-size: 15px;
}
.admin-author-header {
  margin-bottom: 2em;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.admin-author-subheader {
  margin-bottom: 1em;
  font-size: 18px;
  text-align: center;
}
.admin-author-block {
  margin-bottom: 1em;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-author-header {
    font-size: 20px;
  }
  .admin-author-subheader {
    font-size: 16px;
  }
}
@media screen and (max-width: 400px) {
}

.admin-courses-header {
  font-size: 22px;
  text-align: center;
}
.admin-courses-new-course {
  margin: 2em 0 !important;
  border-radius: 0px !important;
  background-color: #009b72 !important;
  color: #fff !important;
  font-size: 16px !important;
}
.admin-courses-class-search{margin:2rem 0}
.admin-courses-card-header {
  font-size: 18px;
}
.admin-courses-card-description {
  font-size: 14px;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-courses-header {
    font-size: 16px;
  }
  .admin-courses-new-course {
    margin: 1em 0 !important;
    font-size: 14px !important;
  }
  .admin-courses-card-header {
    font-size: 14px !important;
  }
  .admin-courses-card-description {
    font-size: 10px;
  }
}
@media screen and (max-width: 400px) {
}

.admin-courses-new-course-cover {
  width: 30%;
}
@media screen and (max-width: 800px) {
  .admin-courses-new-course-cover {
    width: 40%;
  }
}
@media screen and (max-width: 600px) {
  .admin-courses-new-course-cover {
    width: 50%;
  }
}
@media screen and (max-width: 400px) {
  .admin-courses-new-course-cover {
    width: 70%;
  }
}

.admin-block-question-form-option {
  display: flex;
  flex-direction: row;
}

.admin-block-question-delete-option {
  cursor: pointer;
  opacity: 0.5;
}

.admin-block-question-delete-option:hover {
  opacity: 1;
}

.dashboard-lesson-block-question {
    padding: 0px 20px;
}
.dashboard-lesson-block-question-header {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}
.dashboard-lesson-block-question-text {
    margin-bottom: 32px;
    padding: 0px;
    line-height: 1.6;
    font-size: 22px;
}
.dashboard-lesson-block-question-slot {
    display: inline-block;
    padding: 0 4px;
    min-width: 120px;
    min-height: 32px;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 4px;
    outline: none;
    line-height: 1.6;
    font-family: inherit;
    font-size: 20px;
    text-align: center;
    vertical-align: bottom;
}
.dashboard-lesson-block-question-hint {
    line-height: 1.6;
    font-size: 20px;
}
.dashboard-lesson-block-question-hint-button {
    margin-bottom: 32px !important;
}
.dashboard-lesson-block-question-hint-text {
    margin: 0px;
    padding: 24px 32px;
    border-left: 1px solid #000;
}
.dashboard-lesson-block-question-form {
    margin: 32px 0px;
    padding: 48px 72px;
    box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.05);
}
.dashboard-lesson-block-question-form-instruction {
    font-size: 20px;
    margin-bottom: 32px;
}
.dashboard-lesson-block-question-form-option {
    display: flex;
    flex-direction: row;
}
.dashboard-lesson-block-question-form-checkbox,
.dashboard-lesson-block-question-form-radio {
    margin-right: 8px;
    font-size: 20px !important;
    font-style: italic !important;
}
.dashboard-lesson-block-question-form-time {
    font-size: 20px;
}
.dashboard-lesson-block-question-explanation-correct {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #2cd973;
}
.dashboard-lesson-block-question-explanation-wrong {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #ec0042;
}
.dashboard-lesson-block-question-explanation-indefinite {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid slategray;
}

@media only screen and (max-width: 800px) {
    .dashboard-lesson-block-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 24px;
        font-size: 20px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 24px;
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-slot {
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.4;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 24px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 16px 24px;
    }
    .dashboard-lesson-block-question-form {
        margin: 24px 0px;
        padding: 32px 64px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 16px;
        margin-bottom: 24px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 16px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 16px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 16px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-lesson-block-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 16px;
        font-size: 20px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 16px;
        line-height: 1.2;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-slot {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 16px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 8px 24px;
    }
    .dashboard-lesson-block-question-form {
        margin: 16px 0px;
        padding: 24px 40px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 16px;
        margin-bottom: 16px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 8px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 8px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 8px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-lesson-block-question {
        padding: 0px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 8px;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 8px;
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-slot {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 8px !important;
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 8px 16px;
    }
    .dashboard-lesson-block-question-form {
        margin: 12px 0px;
        padding: 16px 24px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 14px;
        margin-bottom: 12px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 8px 16px;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 8px 16px;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 8px 16px;
        font-size: 14px;
    }
}

.ql-editor blockquote {
    padding: .1em 2rem;
    border-left: 8px solid rgb(65, 148, 136);
    line-height: 1.6;
    position: relative;
    background: #fafafa;
}

.tooltip {
    position: relative;
    display: inline-block;
    background: antiquewhite;
    padding: 1px 5px;
    border-radius: 5px;
    font-size: 95%;
    cursor: help;
}
  
/* Tooltip text */
.tooltip > span {
    visibility: hidden;
    width: -webkit-max-content;
    width: max-content;
    max-width: 500px;
    font-size: 16px;
    background-color: #3791d4;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    position: absolute;
    top: calc(100% + 5px);
    z-index: 1;
}

.tooltip[flow^="left"] > span {
    left: 0;
}
.tooltip[flow^="right"] > span {
    right: 0;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover > span {
    visibility: visible;
}

.dashboard-lesson-block-interactive-question {
    padding: 0px 20px;
}
.dashboard-lesson-block-interactive-question-text {
    margin-bottom: 32px;
    padding: 0px;
    line-height: 1.6;
    font-size: 22px;
}
.dashboard-lesson-block-interactive-question-slot,
.dashboard-lesson-block-interactive-question-slot-option {
    display: inline-block;
    padding: 0 4px;
    min-width: 120px;
    min-height: 32px;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 4px;
    outline: none;
    line-height: 1.6;
    font-family: inherit;
    font-size: 20px;
    text-align: center;
    vertical-align: bottom;
}
.dashboard-lesson-block-interactive-question-match-slot {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 64px;
    height: 100%;
    min-height: 32px;
    padding: 0 8px;
    background-color: #ffffff;
    border: 1px dashed #eaeaea;
    border-radius: 4px;
}
.dashboard-lesson-block-interactive-question-match-slot.active {
    border-color: #0073e6 !important;
}
.dashboard-lesson-block-interactive-question-match-slot:hover {
    cursor: pointer;
}
.dashboard-lesson-block-interactive-question-select-words {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.dashboard-lesson-block-interactive-question-select-words:hover {
    cursor: pointer;
}
.dashboard-lesson-block-interactive-question-select-words span.selected {
    background-color: #000;
    color: #fff;
}
.dashboard-lesson-block-interactive-question-select-words span.selected,
.dashboard-lesson-block-interactive-question-select-words span.correct,
.dashboard-lesson-block-interactive-question-select-words span.incorrect {
    padding: 2px;
    border-radius: 2px;
}
.dashboard-lesson-block-interactive-question-slot.correct,
.dashboard-lesson-block-interactive-question-slot-option.correct,
.dashboard-lesson-block-interactive-question-group-element.correct,
.dashboard-lesson-block-interactive-question-match-slot.correct,
.dashboard-lesson-block-interactive-question-select-words span.correct {
    background-color: #338e6b !important;
    color: #fff;
}
.dashboard-lesson-block-interactive-question-slot.incorrect,
.dashboard-lesson-block-interactive-question-slot-option.incorrect,
.dashboard-lesson-block-interactive-question-group-element.incorrect,
.dashboard-lesson-block-interactive-question-match-slot.incorrect,
.dashboard-lesson-block-interactive-question-select-words span.incorrect {
    background-color: #ea4643 !important;
    color: #fff;
}
.dashboard-lesson-block-interactive-question-controls-container {
    margin-top: 8px;
}
.dashboard-lesson-block-interactive-question-hint {
    margin-top: 16px;
    line-height: 1.6;
    font-size: 20px;
}
.dashboard-lesson-block-interactive-question-hint-button {
    margin-bottom: 16px !important;
}
.dashboard-lesson-block-interactive-question-hint-text {
    margin: 0;
    padding: 20px 32px;
    border-left: 1px solid #000;
}
.dashboard-lesson-block-interactive-question-timer {
    font-size: 20px;
}
.dashboard-lesson-block-interactive-question-explanation-correct {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #2cd973;
}
.dashboard-lesson-block-interactive-question-explanation-wrong {
    padding: 24px 32px;
    font-size: 20px;
    border-left: 1px solid #ec0042;
}

@media only screen and (max-width: 800px) {
    .dashboard-lesson-block-interactive-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-interactive-question-text {
        margin-bottom: 24px;
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-interactive-question-slot,
    .dashboard-lesson-block-interactive-question-slot-option {
        line-height: 1.4;
        font-size: 18px;
    }
    .dashboard-lesson-block-interactive-question-hint {
        line-height: 1.4;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-hint-button {
        margin-bottom: 12px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-interactive-question-hint-text {
        padding: 16px 24px;
    }
    .dashboard-lesson-block-interactive-question-timer {
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-correct {
        padding: 16px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-wrong {
        padding: 16px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-lesson-block-interactive-question {
        padding: 0px 24px;
    }
    .dashboard-lesson-block-interactive-question-text {
        margin-bottom: 16px;
        line-height: 1.2;
        font-size: 18px;
    }
    .dashboard-lesson-block-interactive-question-slot,
    .dashboard-lesson-block-interactive-question-slot-option {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-hint {
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-hint-button {
        margin-bottom: 10px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-interactive-question-hint-text {
        padding: 8px 24px;
    }
    .dashboard-lesson-block-interactive-question-timer {
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-correct {
        padding: 8px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-explanation-wrong {
        padding: 8px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-lesson-block-interactive-question {
        padding: 0px;
    }
    .dashboard-lesson-block-interactive-question-text {
        margin-bottom: 8px;
        line-height: 1.2;
        font-size: 16px;
    }
    .dashboard-lesson-block-interactive-question-slot,
    .dashboard-lesson-block-interactive-question-slot-option {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-hint {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-hint-button {
        margin-bottom: 8px !important;
        font-size: 14px !important;
    }
    .dashboard-lesson-block-interactive-question-hint-text {
        padding: 8px 16px;
    }
    .dashboard-lesson-block-interactive-question-timer {
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-explanation-correct {
        padding: 8px 16px;
        font-size: 14px;
    }
    .dashboard-lesson-block-interactive-question-explanation-wrong {
        padding: 8px 16px;
        font-size: 14px;
    }
}

.admin-user-container {
  font-size: 15px;
}
.admin-user-header {
  margin-bottom: 2em;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.admin-user-subheader {
  margin-bottom: 1em;
  font-size: 18px;
  text-align: center;
}
.admin-user-block {
  margin-bottom: 1em;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-user-header {
    font-size: 20px;
  }
  .admin-user-subheader {
    font-size: 16px;
  }
}
@media screen and (max-width: 400px) {
}

.admin-teachers-subheader {
  margin: 2em auto;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.admin-teachers-new-teacher {
  margin: 2em 0 0.6em 0 !important;
  border-radius: 0px !important;
  background-color: #009b72 !important;
  color: #fff !important;
  font-size: 16px !important;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-teachers-subheader {
    font-size: 16px;
  }
  .admin-teachers-new-teacher {
    margin: 1em 0 0.4em 0 !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 400px) {
}

.admin-teacher-container {
  font-size: 15px;
}
.admin-teacher-header {
  margin-bottom: 2em;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.admin-teacher-subheader {
  margin-bottom: 1em;
  font-size: 18px;
  text-align: center;
}
.admin-teacher-block {
  margin-bottom: 1em;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
  .admin-teacher-header {
    font-size: 20px;
  }
  .admin-teacher-subheader {
    font-size: 16px;
  }
}
@media screen and (max-width: 400px) {
}

.dashboard {
  display: grid;
  grid-template-areas: 'course-navigation content menu-navigation';
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 10fr 2fr;
  min-height: 100vh;
}
.dashboard .course-navigation {
  display: flex;
  justify-content: flex-start;
  grid-area: course-navigation;
}
.dashboard .content {
  grid-area: content;
  padding: 72px 24px;
  width: 100%;
}
.dashboard .menu-navigation {
  display: flex;
  justify-content: flex-end;
  grid-area: menu-navigation;
}
.dashboard .mobile-navigation {
  display: none;
}
.dashboard h1 {
  margin-bottom: 0.6em;
  color: #000;
  font-family: inherit;
  font-size: 36px;
  line-height: 48px;
}
.dashboard h2 {
  margin-bottom: 1.4em;
  color: #000;
  font-family: inherit;
  font-size: 32px;
  line-height: 32px;
}

@media print {
  .dashboard .course-navigation {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .dashboard .content {
    padding: 64px 16px;
  }
  .dashboard h1 {
    font-size: 30px;
    line-height: 36px;
  }
  .dashboard h2 {
    font-size: 24px;
    line-height: 24px;
  }
}
@media screen and (max-width: 600px) {
  .dashboard {
    display: flex;
  }
  .dashboard .course-navigation,
  .dashboard .menu-navigation {
    display: none;
  }
  .dashboard .content {
    padding: 48px 32px 80px 32px;
  }
  .dashboard .mobile-navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0px;
    width: 100%;
    height: 64px;
    background-color: rgba(255, 255, 255, 0.75);
    border-top: 2px solid #4682e0;
  }
  .dashboard .mobile-navigation #print-button {
    display: none;
  }
  .dashboard h1 {
    font-size: 26px;
    line-height: 30px;
  }
  .dashboard h2 {
    font-size: 22px;
    line-height: 22px;
  }
}
@media screen and (max-width: 400px) {
  .dashboard .content {
    padding: 32px 24px 72px 24px;
  }
  .dashboard h1 {
    font-size: 24px;
    line-height: 24px;
  }
  .dashboard h2 {
    font-size: 20px;
    line-height: 20px;
  }
  .dashboard .mobile-navigation {
    height: 56px;
  }
}

[data-name] {
    transition: all .3s ease;
}

[data-name].active {
    position: relative;
}

[data-name].active::before {
    content: '';
    position: absolute;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    left: -0.5rem;
    top: -0.5rem;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px #4682E0;
}

[data-name] {
    transition: all .3s ease;
}

[data-name].active {
    position: relative;
}

[data-name].active::before {
    content: '';
    position: absolute;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    left: -0.5rem;
    top: -0.5rem;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px #4682E0;
}

.filter_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 1.5rem;
}

.filter_wrapper .select_wrapper {
    position: relative;
    display: flex;
    width: 256px;
}

.filter_wrapper .select_wrapper .select_title {
    position: relative;
    background: #fff;
    cursor: pointer;
    padding: 0.78571429em 2.1em 0.78571429em 1em;
    color: rgba(0,0,0,.87);
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 0.28571429rem;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.filter_wrapper .select_wrapper .select_title svg {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: .75rem;
    transition: -webkit-transform .25s ease;
    transition: transform .25s ease;
    transition: transform .25s ease, -webkit-transform .25s ease;
}

.filter_wrapper .select_wrapper .hidden {
    display: none;
}

.filter_wrapper .select_wrapper .peer ~ .option_wrapper {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #96c8da;
    z-index: 1;
    background: #fff;
    border-radius: 0.28571429rem;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    padding: .25rem 0;
    width: 100%;
    max-height: 356px;
    overflow-y: auto;
}

.filter_wrapper .select_wrapper .peer:checked ~ .option_wrapper {
    display: flex;
}

.filter_wrapper .select_wrapper .peer:checked ~ .select_title svg {
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
}

.filter_wrapper .select_wrapper .option_wrapper label {
    position: relative;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    padding: 0.78571429em 2.1em 0.78571429em 1em;
}

.filter_wrapper .select_wrapper .option_wrapper label span {
    margin-left: .25rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filter_wrapper .select_wrapper .option_wrapper label span::after {
    content: '';
    position: absolute;
    inset: 0;
}

.filter_wrapper .select_wrapper .option_wrapper label:hover span::after {
    background: rgba(0,0,0,.05);
}

.filter_wrapper .select_wrapper .option_wrapper label input:checked ~ span::after {
    background: rgba(0,0,0,.03);
}

.search_wrapper,
.tags_wrapper {
    width: calc(256px * 3 + 16px * 2);
}

.tags_wrapper {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;
    -webkit-user-select: none;
            user-select: none;
}

.tags_wrapper label {
    display: flex;
    position: relative;
    background: rgba(0,0,0,.03);
    border-radius: 999px;
    padding: .25rem 2.1rem .25rem 0.75rem;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 90%;
    transition: all .25s ease;
    align-self: center;
}
.tags_wrapper label:hover {
    background: rgba(0,0,0,.05);
}
.tags_wrapper label::after {
    content: '\D7';
    background: rgba(0,0,0,.05);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: .25rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border-radius: 999px;
    font-weight: 400;
}
.tags_wrapper label:hover .tags_wrapper label::after {
    background: rgba(0,0,0,.07);
}

@media (min-width: 600px) and (max-width: 800px) {
    .filter_wrapper .select_wrapper {
        width: 196px;
    }
    .search_wrapper,
    .tags_wrapper {
        width: calc(196px * 3 + 14px * 2);
    }
}
@media (min-width: 400px) and (max-width: 600px) {
    .filter_wrapper {
        grid-gap: 8px;
        gap: 8px;
    }
    .filter_wrapper .select_wrapper {
        width: 160px;
    }
    .search_wrapper,
    .tags_wrapper {
        width: calc(160px * 3 + 8px * 2);
    }
}

@media (max-width: 400px) {
    .filter_wrapper {
        grid-gap: 8px;
        gap: 8px;
    }
    .filter_wrapper .select_wrapper {
        width: 144px;
    }
    .search_wrapper,
    .tags_wrapper {
        width: calc(144px * 3 + 14px * 2);
    }
}
.dashboard-lesson-footer-wrapper {
    background: #f2f2f2;
    /* margin: 0px 16px 40px 16px; */
    padding: 32px 96px;
    font-size: 16px;
    text-align: center;
}
.dashboard-lesson-footer-header {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 32px;
    /* text-transform: uppercase; */
}
.dashboard-lesson-footer-stats {
    display: grid;
    align-content: center;
    justify-content: space-between;
    grid-template-areas:
        "rightText totalText timeText"
        "rightNumber totalNumber timeNumber";
}
.dashboard-lesson-footer-stats-right-text,
.dashboard-lesson-footer-stats-total-text,
.dashboard-lesson-footer-stats-time-text {
    text-transform: lowercase;
}
.dashboard-lesson-footer-stats-right-text {
    grid-area: rightText;
}
.dashboard-lesson-footer-stats-total-text {
    grid-area: totalText;
}
.dashboard-lesson-footer-stats-time-text {
    grid-area: timeText;
}
.dashboard-lesson-footer-stats-right-number,
.dashboard-lesson-footer-stats-total-number,
.dashboard-lesson-footer-stats-time-number {
    font-size: 24px;
    font-weight: bold;
}
.dashboard-lesson-footer-stats-right-number {
    grid-area: rightNumber;
}
.dashboard-lesson-footer-stats-total-number {
    grid-area: totalNumber;
}
.dashboard-lesson-footer-stats-time-number {
    grid-area: timeNumber;
}
.dashboard-lesson-footer-questions {
    margin: 0 auto 32px auto;
    width: 80%;
    text-align: center;
}
.dashboard-lesson-footer-questions-indefinite {
    color: #47484b;
}
.dashboard-lesson-footer-questions-correct {
    color: #198c19;
}
.dashboard-lesson-footer-questions-wrong {
    color: #cc0000;
}
@media only screen and (max-width: 800px) {
    .dashboard-lesson-footer-wrapper {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-lesson-footer-header {
        margin-bottom: 20px;
        font-size: 18px;
    }
    .dashboard-lesson-footer-stats-right-number,
    .dashboard-lesson-footer-stats-total-number,
    .dashboard-lesson-footer-stats-time-number {
        font-size: 18px;
    }
    .dashboard-lesson-footer-questions {
        margin: 24px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-lesson-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 24px 32px;
        font-size: 14px;
    }
    .dashboard-lesson-footer-header {
        margin-bottom: 16px;
        font-size: 16px;
    }
    .dashboard-lesson-footer-stats-right-number,
    .dashboard-lesson-footer-stats-total-number,
    .dashboard-lesson-footer-stats-time-number {
        font-size: 16px;
    }
    .dashboard-lesson-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-lesson-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 16px 32px;
        font-size: 10px;
    }
    .dashboard-lesson-footer-header {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .dashboard-lesson-footer-stats-right-number,
    .dashboard-lesson-footer-stats-total-number,
    .dashboard-lesson-footer-stats-time-number {
        font-size: 12px;
    }
    .dashboard-lesson-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

.dashboard-materials-container {
    font-size: 14px;
    color: #000;
}
.dashboard-materials-container-header {
    margin-bottom: 2em;
    font-size: 22px;
    text-align: center;
}
.dashboard-materials-container-lesson {
    padding: 1em;
    border-bottom: 1px dashed rgba(105, 105, 105, 0.25);
}
.dashboard-materials-container-lesson-header {
    margin-bottom: 1em;
    font-size: 16px;
    font-weight: 400;
}
@media screen and (max-width: 800px) {
    .dashboard-materials-container {
        padding: 0 40px;
    }
    .dashboard-materials-container-header {
        font-size: 20px;
    }
}
@media screen and (max-width: 600px) {
    .dashboard-materials-container {
        padding: 0 24px;
        font-size: 12px;
    }
    .dashboard-materials-container-header {
        font-size: 18px;
    }
    .dashboard-materials-container-lesson-header {
        font-size: 14px;
    }
}
@media screen and (max-width: 400px) {
    .dashboard-materials-container {
        font-size: 10px;
    }
    .dashboard-materials-container-header {
        font-size: 16px;
    }
    .dashboard-materials-container-lesson-header {
        font-size: 12px;
    }
}

.dashboard-practice-forbidden,
.dashboard-practice-not-found {
    font-size: 20px;
}
.dashboard-practice-forbidden-image {
    padding-top: 24px;
    width: 50%;
}
.dashboard-practice-not-found-image {
    width: 60%;
}
.dashboard-practice-name {
    margin-bottom: 2em;
    padding: 0 72px;
    font-size: 36px;
    line-height: 1.2;
    text-align: left;
}
.dashboard-practice-name-id {
    margin-right: 1em;
}
.dashboard-practice-container {
    font-size: 18px;
    font-weight: 300;
}
.dashboard-practice-question {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-bottom: 1em;
    border-bottom: 1px dashed rgba(105, 105, 105, 0.25);
}
.dashboard-practice-question p {
    line-height: 1.4;
}
.dashboard-practice-stars {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-bottom: 2em;
}
.dashboard-practice-star {
    margin: 0 16px;
    width: 48px;
    height: 48px;
    opacity: 1;
    -webkit-animation-name: star-animation;
    -webkit-animation-duration: 4s;
    animation-name: star-animation;
    animation-duration: 4s;
}

@-webkit-keyframes star-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes star-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.dashboard-practice-next-question-button {
    margin-left: 120px;
}
.dashboard-practice-next-lesson {
    display: flex;
    justify-content: center;
    margin-top: 64px;
}
.dashboard-practice-next-lesson-link,
.dashboard-practice-next-lesson-link-inactive {
    cursor: pointer;
    color: #000;
    font-size: 32px;
    line-height: 1.2;
    margin: 0px 16px;
    text-align: center;
    text-decoration: none;
    /* text-transform: lowercase; */
}
.dashboard-practice-next-lesson-link:hover {
    color: slategrey;
}
.dashboard-practice-next-lesson-link-inactive {
    cursor: default;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 18px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 2em;
        width: 60%;
    }
    .dashboard-practice-name {
        font-size: 32px;
        line-height: 1.6;
    }
    .dashboard-practice-name-id {
        margin-right: 1em;
    }
    .dashboard-practice-container {
        font-size: 16px;
    }
    .dashboard-practice-question {
        margin-bottom: 3em;
    }
    .dashboard-practice-question p {
        line-height: 1.4;
    }
    .dashboard-practice-stars {
        margin-bottom: 3em;
    }
    .dashboard-practice-star {
        margin: 0 24px;
        width: 40px;
        height: 40px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 72px;
    }
    .dashboard-practice-next-lesson {
        margin-top: 4em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 24px;
    }
}
@media only screen and (max-width: 600px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 16px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 3em;
        width: 90%;
    }
    .dashboard-practice-name {
        padding: 0 64px;
        font-size: 28px;
        line-height: 1.2;
    }
    .dashboard-practice-name-id {
        margin-right: 1em;
    }
    .dashboard-practice-container {
        font-size: 14px;
    }
    .dashboard-practice-question {
        margin-bottom: 2em;
    }
    .dashboard-practice-question p {
        line-height: 1.2;
    }
    .dashboard-practice-stars {
        margin-bottom: 2em;
    }
    .dashboard-practice-star {
        margin: 0 16px;
        width: 32px;
        height: 32px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 3em;
    }
    .dashboard-practice-next-lesson {
        margin-top: 2em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 22px;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 14px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 3em;
        width: 90%;
    }
    .dashboard-practice-name {
        padding: 0 40px;
        font-size: 24px;
        line-height: 1.1;
    }
    .dashboard-practice-name-id {
        margin-right: 0.6em;
    }
    .dashboard-practice-container {
        font-size: 10px;
    }
    .dashboard-practice-question {
        margin-bottom: 1.6em;
    }
    .dashboard-practice-question p {
        line-height: 1.1;
    }
    .dashboard-practice-stars {
        margin-bottom: 2em;
    }
    .dashboard-practice-star {
        margin: 0 8px;
        width: 24px;
        height: 24px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 2em;
    }
    .dashboard-practice-next-lesson {
        margin-top: 4em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 18px;
    }
}

.dashboard-practice-footer-wrapper {
    background: #f2f2f2;
    margin: 0px 16px 40px 16px;
    padding: 32px 96px;
    font-size: 16px;
    text-align: center;
}
.dashboard-practice-footer-header {
    margin-bottom: 24px;
    font-size: 20px;
    text-transform: uppercase;
}
.dashboard-practice-footer-stats {
    display: grid;
    align-content: center;
    justify-content: space-between;
    grid-template-areas:
        "rightText totalText timeText"
        "rightNumber totalNumber timeNumber";
}
.dashboard-practice-footer-stats-right-text,
.dashboard-practice-footer-stats-total-text,
.dashboard-practice-footer-stats-time-text {
    /* text-transform: lowercase; */
}
.dashboard-practice-footer-stats-right-text {
    grid-area: rightText;
}
.dashboard-practice-footer-stats-total-text {
    grid-area: totalText;
}
.dashboard-practice-footer-stats-time-text {
    grid-area: timeText;
}
.dashboard-practice-footer-stats-right-number,
.dashboard-practice-footer-stats-total-number,
.dashboard-practice-footer-stats-time-number {
    font-size: 24px;
    font-weight: bold;
}
.dashboard-practice-footer-stats-right-number {
    grid-area: rightNumber;
}
.dashboard-practice-footer-stats-total-number {
    grid-area: totalNumber;
}
.dashboard-practice-footer-stats-time-number {
    grid-area: timeNumber;
}
.dashboard-practice-footer-questions {
    margin: 0 auto 32px auto;
    width: 80%;
    text-align: center;
}
.dashboard-practice-footer-questions-indefinite {
    color: #47484b;
}
.dashboard-practice-footer-questions-correct {
    color: #198c19;
}
.dashboard-practice-footer-questions-wrong {
    color: #cc0000;
}
.dashboard-practice-footer-extra {
    text-align: left;
}
.dashboard-practice-footer-extra-header {
    margin-bottom: 1em;
    font-weight: 400;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 20px;
        font-size: 18px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 18px;
    }
    .dashboard-practice-footer-questions {
        margin: 24px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 24px 32px;
        font-size: 14px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 16px;
        font-size: 16px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 16px;
    }
    .dashboard-practice-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 16px 32px;
        font-size: 10px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 12px;
    }
    .dashboard-practice-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

.dashboard-practice-header-stats {
    background: #f2f2f2;
    margin: 0px 16px 40px 16px;
    padding: 32px 80px;
    font-size: 16px;
    text-align: center;
}
.dashboard-practice-header-stats-header {
    font-size: 20px;
    /* text-transform: uppercase; */
}
.dashboard-practice-header-stats-results {
    display: grid;
    align-content: center;
    justify-content: space-between;
    grid-template-areas:
        "subheader subheader"
        "rightText totalText"
        "rightNumber totalNumber";
}
.dashboard-practice-header-stats-results-subheader {
    grid-area: subheader;
    font-size: 18px;
    /* text-transform: uppercase; */
}
.dashboard-practice-header-stats-results-right-text {
    grid-area: rightText;
    /* text-transform: lowercase; */
}
.dashboard-practice-header-stats-results-total-text {
    grid-area: totalText;
    /* text-transform: lowercase; */
}
.dashboard-practice-header-stats-results-right-number {
    grid-area: rightNumber;
    font-size: 26px;
    font-weight: bold;
    line-height: 1;
}
.dashboard-practice-header-stats-results-total-number {
    grid-area: totalNumber;
    font-size: 26px;
    font-weight: bold;
    line-height: 1;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-header-stats {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 18px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 16px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 22px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 22px;
        line-height: 1;
    }
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-header-stats {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 18px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 16px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 22px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 22px;
        line-height: 1;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-header-stats {
        margin: 0px 24px 32px 24px;
        padding: 16px 40px;
        font-size: 12px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 14px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 14px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 18px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 18px;
        line-height: 1;
    }
}

.dashboard-practice-question-header {
    margin-bottom: 32px;
    padding: 0px 120px;
    font-size: 22px;
    /* text-transform: uppercase; */
    /* font-weight: 500; */
}
.dashboard-practice-question-text {
    margin-bottom: 32px;
    padding: 0 120px;
    font-size: 20px;
}
.dashboard-practice-question-image {
    margin-bottom: 32px;
    padding: 0 32px;
}
.dashboard-practice-question-hint {
    padding: 0 120px;
}
.dashboard-practice-question-hint-text {
    padding: 24px 48px;
    border-left: 1px solid #000;
}
.dashboard-practice-question-form {
    margin: 32px 24px;
    padding: 40px 64px;
    box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.05);
    font-size: 18px;
}
.dashboard-practice-question-form-instruction {
    font-size: 18px;
    margin-bottom: 24px;
}
.dashboard-practice-question-explanation-correct {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid #2cd973;
}
.dashboard-practice-question-explanation-wrong {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid #ec0042;
}
.dashboard-practice-question-explanation {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid slategray;
}
.dashboard-practice-question-explanation-correct,
.dashboard-practice-question-explanation-wrong,
.dashboard-practice-question-explanation {
    font-size: 18px;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-question-header {
        padding: 0px 80px;
        font-size: 22px;
    }
    .dashboard-practice-question-text {
        padding: 0 80px;
        font-size: 18px;
    }
    .dashboard-practice-question-image {
        padding: 0 24px;
    }
    .dashboard-practice-question-hint {
        padding: 0 80px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 16px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 32px 48px;
        padding: 32px 80px;
        font-size: 14px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 18px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 40px;
        font-size: 16px;
    }
}
@media only screen and (max-width: 600px) {
    .dashboard-practice-question-header {
        padding: 0px 64px;
        font-size: 20px;
    }
    .dashboard-practice-question-text {
        padding: 0 64px;
        font-size: 18px;
    }
    .dashboard-practice-question-image {
        padding: 0 24px;
    }
    .dashboard-practice-question-hint {
        padding: 0 64px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 16px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 32px 40px;
        padding: 32px 64px;
        font-size: 14px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 18px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 40px;
        font-size: 16px;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-question-header {
        padding: 0px 40px;
        font-size: 18px;
    }
    .dashboard-practice-question-text {
        padding: 0 40px;
        font-size: 16px;
    }
    .dashboard-practice-question-image {
        padding: 0 16px;
    }
    .dashboard-practice-question-hint {
        padding: 0 40px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 14px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 16px 24px;
        padding: 32px 40px;
        font-size: 12px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 16px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 24px;
        font-size: 14px;
    }
}

.dashboard-practice-practices-list-group {
    margin-bottom: 1.6em;
}
.dashboard-practice-practices-list-group-name {
    margin-bottom: 1em;
    font-size: 18px;
    font-weight: 500;
}
.dashboard-practice-practices-list-block {
    margin-bottom: 1em;
    font-size: 16px;
}
.dashboard-practice-practices-list-link {
    color: #000;
}
.dashboard-practice-practices-list-link:hover {
    color: #646464;
    border-bottom: 1px solid #646464;
}
@media screen and (max-width: 800px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 16px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 14px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 12px;
    }
}
@media screen and (max-width: 400px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 14px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 12px;
    }
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;

}

.App {
  background-color: #3c3c56;
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  min-height: 100%;
  justify-content: center;

}

.wp {
  flex: 1 1;
}

.WelcomePage {
  flex: 1 1;
  overflow-y: auto; /* Добавьте скролл, если содержимое WelcomePage превышает высоту экрана */
}

.custom-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.custom-loader::after , .custom-loader::before  {
  content: '';
  display: inline-block;
  width: 48px;
  height: 48px;
  background-color: #FFF;
  background-image:  radial-gradient(circle 14px, #0d161b 100%, transparent 0);
  background-repeat: no-repeat;
  border-radius: 50%;
  -webkit-animation: eyeMove 10s infinite , blink 10s infinite;
          animation: eyeMove 10s infinite , blink 10s infinite;
}
@-webkit-keyframes eyeMove {
  0%  , 10% {     background-position: 0 0}
  13%  , 40% {     background-position: -15px 0}
  43%  , 70% {     background-position: 15px 0}
  73%  , 90% {     background-position: 0 15px}
  93%  , 100% {     background-position: 0 0}
}
@keyframes eyeMove {
  0%  , 10% {     background-position: 0 0}
  13%  , 40% {     background-position: -15px 0}
  43%  , 70% {     background-position: 15px 0}
  73%  , 90% {     background-position: 0 15px}
  93%  , 100% {     background-position: 0 0}
}
@-webkit-keyframes blink {
  0%  , 10% , 12% , 20%, 22%, 40%, 42% , 60%, 62%,  70%, 72% , 90%, 92%, 98% , 100%
  { height: 48px}
  11% , 21% ,41% , 61% , 71% , 91% , 99%
  { height: 18px}
}
@keyframes blink {
  0%  , 10% , 12% , 20%, 22%, 40%, 42% , 60%, 62%,  70%, 72% , 90%, 92%, 98% , 100%
  { height: 48px}
  11% , 21% ,41% , 61% , 71% , 91% , 99%
  { height: 18px}
}
.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    width: 100%;

    position: fixed;
    z-index: 9999;
}
.navigcia h3 {
    font-family: 'Montserrat SemiBold', sans-serif;
    margin: 1rem 0;
}

.logoimg img {
    width: 135px;
    cursor: pointer;
}

.logo img {
    width: 100%;
    margin-left: 60px;
}

.logoimg {
    margin-left: 40px;
}

.navigcia-first {
    list-style: none;
    cursor: pointer;
}

.navigcia-first:hover {
    color: gray;
}

.navigcia-centr {
    list-style: none;
    cursor: pointer;
}

.navigcia-centr:hover {
    color: gray;
}

.navigcia-last {
    list-style: none;
    cursor: pointer;
}

.navigcia-last:hover {
    color: gray;
}

.navigcia li {
    display: inline-block;
    margin-right: 20px;
    font-size: 20px


}

.nav-and-lang {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-right: 20px;

}
/* Для Polygon1.svg */
.langselect[data-background="Polygon1"] {
    background-image: url("/static/media/Polygon 1.638b1c35.svg");
    background-size: 10px; /* Измените размер на подходящий вам */
}

/* Для Polygon2.svg */
.langselect[data-background="Polygon2"] {
    background-image: url(/static/media/треугольник.8bf6a4ca.svg);
    background-size: 10px; /* Измените размер на подходящий вам */
}

/* Остальные стили вашего .langselect */

.langselect {
    -webkit-appearance: none;
    -moz-appearance: none;

    position: relative;
    margin-right: 30px;
    height: 28px;
    width: 120px;
    font-size: 17px;
    border-radius: 5px ;
    background-color: #00B9F8;
    color: white;
    border: none;
    appearance: none; /* Скрываем стандартные стили селекта */
    background-repeat: no-repeat; /* Отключаем повторение фонового изображения */
    background-position: right 10px center; /* Выравнивание изображения справа по центру */
    padding-right: 20px; /* Добавляем отступ справа для размещения изображения */
    outline: none;
    padding-left: 5px;
    font-family: 'Montserrat SemiBold', sans-serif;
}

.langselect option {
    background-color: white;
    color: black;
    font-family: 'Montserrat Regular', sans-serif;
    padding: 5px 10px; /* Задаем внутренний отступ для опций */
    border-radius: 0!important;
}








.mobile-menu {
    display: none; /* По умолчанию скрыть мобильное меню */

}

.mobile-menu-button {
    display: none; /* По умолчанию скрыть кнопку на больших экранах */

}

.togl-mob {
    display: none;
}




@media (min-width: 2000px) {
    .langselect {
        margin-right: 30px;
        height: 40px;
        width: 140px;
        font-size: 22px;
    }
    .logoimg img {
        width: 200%;
        cursor: pointer;
    }
}
@media (max-width: 1001px) {
    .navigcia-first-mob, .navigcia-centr-mob, .navigcia-last-mob {
        font-family: 'Montserrat SemiBold', sans-serif;

    }

    .Header {

        width: 100%;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .nav-and-lang {
        display: none;
        align-items: center;
        grid-gap: 20px;
        gap: 20px;

    }

    .togl-mob {
        display: block;
        margin-right: 50px;
        margin-top: 10px;
    }

    .langselect-mob {
        height: 28px;
        width: 120px;
        font-size: 15px;
        border-radius: 5px; /* Normal border radius */
        background-color: #00B9F8;
        color: white;
        border:none;
    }

    .langselect-mob option {
        background-color: white;
        color: black;
        border-radius: 0;
    }

    .logo img {
        width: 100px; /* Уменьшаем ширину логотипа */
        margin-left: 10px; /* Уменьшаем отступ слева */
    }

    .logoimg {
        margin-left: 10px; /* Уменьшаем отступ слева */
    }

    .navigcia li {
        display: block; /* Отображаем элементы списка в столбец */
        margin-right: 0; /* Убираем правый отступ */
    }

    .langselect {
        margin-right: 10px; /* Уменьшаем отступ справа */
        width: 100px; /* Уменьшаем ширину селекта */
        font-size: 14px; /* Уменьшаем размер шрифта */
    }

    /* Стили для кнопки гамбургера */
    .mobile-menu-button {
        display: block;
        cursor: pointer;
        font-size: 24px;
        position: absolute;
        top: 22px;
        right: 15px; /* Разместить кнопку в правом верхнем углу */
    }

    .navigcia-first-mob {
        list-style: none;
        cursor: pointer;
    }

    .navigcia-first-mob:hover {
        color: gray;
    }

    .navigcia-centr-mob {
        list-style: none;
        cursor: pointer;
    }

    .navigcia-centr-mob:hover {
        color: gray;
    }

    .navigcia-last-mob {
        list-style: none;
        cursor: pointer;
    }

    .navigcia-last-mob:hover {
        color: gray;
    }

    /* Стили для мобильного меню */
    .mobile-menu {
        display: block;
        background-color: #3C3C56; /* Цвет фона */
        position: absolute;
        top: 63px; /* Расположить меню под шапкой */
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        right: 0;
    }

    /* Стили для элементов в мобильном меню */
    .mobile-menu a {
        display: block;
        color: white;
        text-decoration: none;
        margin-bottom: 10px;
        font-size: 18px;
    }

    .nav-and-lang-mob {
        display: flex;
        flex-direction: column; /* Разместить элементы в столбце */
        align-items: flex-end; /* Выравнивать элементы справа */
    }

    /* Стили для блока меню */
    .menu-block {
        display: flex;
        flex-direction: column; /* Размещение элементов в столбце */
        align-items: flex-end; /* Выравнивание элементов справа */
        padding: 10px; /* Внутренний отступ блока */
        background-color: #3C3C56; /* Цвет фона блока */
    }

}
.custom-modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    border: none;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #3C3C56;
    padding: 20px;
    color: #FFFFFF;
    border: none;
    border-radius: 50px;
    width: auto; /* Ограничиваем максимальную ширину контейнера */
    max-width: 850px;
    overflow-y: auto; /* Добавляем полосы прокрутки при необходимости */
    max-height: 90%;
    scrollbar-width: none; /* Скрываем полосу прокрутки для Firefox */
    -ms-overflow-style: none; /* Скрываем полосу прокрутки для IE/Edge */
}
.modal-content::-webkit-scrollbar {
    display: none; /* Скрываем полосу прокрутки для WebKit (Chrome, Safari) */
}
.close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-size: 24px;
}

.close img {
    width: 40px;
}

@media (max-width: 768px) {
    .modal-content {
        width: 70%; /* Уменьшим ширину для мобильных экранов */
        padding: 10px; /* Уменьшим отступы */
    }

    .close {
        font-size: 20px; /* Уменьшим размер кнопки закрытия */
        top: 15px;
        right: 15px;
    }
    .close img {
        width: 30px;
    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    .modal-content {
        width: 53%;; /* Уменьшим ширину для мобильных экранов */
        padding: 10px; /* Уменьшим отступы */
        max-height: 80%;
    }
    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        background-color: #3C3C56;
        padding: 20px;
        color: #FFFFFF;
        border: none;
        border-radius: 20px;
        width: 53%; /* Ограничиваем максимальную ширину контейнера */
        overflow: auto; /* Добавляем полосы прокрутки при необходимости */
        max-height: 90%;
    }
    .close img {
        width: 30px;
    }
}
.instructions {
    text-align: left; /* Выравниваем текст по левому краю */
    margin: 20px; /* Добавляем небольшой отступ от краев */
    font-size: 14px;
}

.instructions iframe {
    aspect-ratio: 4 / 3;
}

.instructions h1 {
    text-align: center; /* Выравниваем заголовки по левому краю */
    margin: 10px 0; /* Отступы между заголовками */
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 27px;
}

.instructions h2 {
    text-align: left; /* Выравниваем заголовки по левому краю */
    margin: 10px 0; /* Отступы между заголовками */
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 20px;
}

.instructions span {
    display: block; /* Каждый span будет отображаться в новой строке */
    text-align: left; /* Выравниваем текст по левому краю */
    margin: 5px 0; /* Отступы между строками */
    font-family: 'Montserrat SemiBold', sans-serif;
}

.instructions .buttonsubmit {
    margin: 20px 0; /* Добавляем вертикальный отступ сверху и снизу */
    display: flex;
    justify-content: center; /* Выравниваем кнопку по горизонтали по центру */
}

.instr {
    margin-top: 20px;
}

@media (max-width: 600px) {
    .instructions {
        text-align: left;
        margin: 10px; /* Уменьшим отступы */
        font-size: 12px; /* Уменьшим размер шрифта */
    }

    .instructions h1{
        font-size: 20px;
    }
    .instructions h2 {
       font-size: 15px;
    }

    .instructions span {
        margin: 3px 0; /* Уменьшим отступы между строками */
    }

    .instructions .buttonsubmit {
        margin: 10px 0; /* Уменьшим вертикальный отступ */
    }

    .instr {
        margin-top: 10px; /* Уменьшим верхний отступ для .instr */
    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    .instructions {
        text-align: left;
        margin: 10px; /* Уменьшим отступы */
        font-size: 2vh;
    }

    .instructions span {
        display: block; /* Каждый span будет отображаться в новой строке */
        text-align: left; /* Выравниваем текст по левому краю */
        margin: 0px 0; /* Отступы между строками */
    }
    .instr {
        margin-top: 2px;
    }
}

.contacts {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 100px;
    margin-right: 100px;
}

.titlecontact h1 {
    text-align: center; /* Выравниваем заголовок по центру */
    margin-bottom: 20px;
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 27px;
}


.conditions .buttonsubmit {
    margin: 20px 0; /* Добавляем вертикальный отступ сверху и снизу */
    display: flex;
    justify-content: center; /* Выравниваем кнопку по горизонтали по центру */
}

.contacts img {
    margin-right: 10px;
    vertical-align: middle; /* Выравнивание по вертикали посреди текста */
}


.contact-item h4 {
    font-size: 20px;
    margin-top: auto;
}

.contact-info {
    display: flex;
    justify-content: center; /* Выравнивание по центру по горизонтали */
}

.contact-item {
    display: flex;
    flex-direction: column; /* Устанавливаем направление контейнера в столбец */
    margin-bottom: 10px; /* Добавляем отступ между элементами .contact-item */
}

.cont {
    display: flex; /* Добавляем стиль flex для вложенных элементов */
    font-family: 'Montserrat SemiBold', sans-serif;
}
.cont:first-child {
    margin: 1rem 0;
}
.titlecontact {
    display: flex;
    justify-content: center;
    margin-bottom: -25px;
    font-family: 'Montserrat Bold', sans-serif;
}


@media (max-width: 600px) {

    .contacts h4 {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 14px;
        max-width: 80%;
    }

    .titlecontact h2 {

        margin-bottom: 20px;
        font-size: 15px;
        font-family: 'Montserrat Bold', sans-serif;
    }
    .titlecontact h1 {
        text-align: center;
        margin-bottom: 20px;
        font-family: 'Montserrat Bold', sans-serif;
        font-size: 20px;
    }
    .cont {
        display: flex;
        align-items: center;
        white-space: nowrap; /* Prevent text from wrapping */
        overflow: hidden; /* Hide overflowed text */
        text-overflow: ellipsis; /* Show ellipsis (...) for overflowed text */
        margin-bottom: -20px;
        font-family: 'Montserrat SemiBold', sans-serif;
    }


}
@media (max-width: 1024px) {
    .cont {
        display: flex;
        margin-left: 20px;
        margin-bottom: -10px;
        font-family: 'Montserrat SemiBold', sans-serif;
    }
}
.conditions {
    text-align: left; /* Выравниваем текст по левому краю */
    margin: 20px; /* Добавляем небольшой отступ от краев */
    font-size: 14px;
}

.conditions h1 {
    text-align: center; /* Выравниваем заголовки по левому краю */
    margin: 10px 0; /* Отступы между заголовками */
    width: 90%;
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 27px;
}

.conditions h2 {
    text-align: left; /* Выравниваем заголовки по левому краю */
    margin: 10px 0; /* Отступы между заголовками */
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 20px;
}

.conditions span {
    display: block; /* Каждый span будет отображаться в новой строке */
    text-align: left; /* Выравниваем текст по левому краю */
    margin: 5px 0; /* Отступы между строками */
    font-family: 'Montserrat SemiBold', sans-serif;
}

.instr {
    margin-top: 20px;
}

@media (max-width: 600px) {
    .conditions {
        text-align: center; /* Выравниваем текст по центру */
        margin: 10px; /* Уменьшаем отступ от краев */
        font-size: 12px; /* Уменьшаем размер шрифта */
    }
    .conditions h1 {

        font-size: 20px;
    }

    .conditions h2 {

        font-size: 15px;
    }


    .conditions span {
        text-align: center; /* Выравниваем текст по центру */
        margin: 4px 0; /* Уменьшаем отступы между строками */
    }

    .instr {
        margin-top: 10px; /* Уменьшаем верхний отступ для .instr */
    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    .conditions {
        font-size: 2vw; /* Уменьшаем размер шрифта */
        text-align: left;
    }

    .conditions span {
        display: block; /* Каждый span будет отображаться в новой строке */
        text-align: left; /* Выравниваем текст по левому краю */
        margin: -5px 0; /* Отступы между строками */
    }

}

.wrg-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.wrg-toggle-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.wrg-toggle-check{
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;

    transition: opacity .25s ease;

}
.wrg-toggle-uncheck {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;

    transition: opacity .25s ease;
}
.wrg-toggle-check {
    left: 8px;
}
.wrg-toggle-uncheck {
    opacity: 1;
    right: 6px;
}

.wrg-toggle-uncheck span{
    align-items: center;
    display: flex;
    height: 10px;
    justify-content: center;
    position: relative;
    width: 10px;
}
.wrg-toggle-check span {
    align-items: center;
    display: flex;
    height: 10px;
    justify-content: center;
    position: relative;
    width: 10px;
}
.wrg-toggle--checked .wrg-toggle-container  {
    background-color: #00B9F8 !important;
}
.wrg-toggle .wrg-toggle-container {
    background-color: #ffffff; /* Цвет фона при выключенной теме */
}
.wrg-toggle .wrg-toggle-circle {
    background-color: #3C3C56; /* Цвет фона при выключенной теме */
}
.wrg-toggle--checked .wrg-toggle-circle  {
    background-color: #ffffff !important;
}

.wrg-toggle-container {
    position: fixed;
    width: 51px;
    height: 25px;
    padding: 0;
    border-radius: 30px;
    transition: all .2s ease;
    z-index: 1;
    right: 20px;
    bottom: 20px;
}


.wrg-toggle-circle {
    transition: all 1s cubic-bezier(.23,1,.32,1) 0ms;
    position: relative; /* Изменено на relative */
    top: 1px;
    left: 1px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    z-index: 1;
    box-sizing: border-box;
    overflow: hidden; /* Добавлено, чтобы скрыть излишки фото */
}


.wrg-toggle img {

    height: 16px; /* Установите желаемую высоту */
    position: absolute;
    z-index: 2;
}

.wrg-toggle--checked .wrg-toggle-check{
     opacity: 1;
 }

.wrg-toggle--checked .wrg-toggle-circle {
    left: 27px;
}


@media (max-width: 1024px) and (orientation: landscape) {
    .wrg-toggle-container {
        left: 90%;
        top: 91%
    }
}
/* @import '../animate.css'; */
.Footer {
        bottom: 0;
        left: 0;
    display: flex;
    width: 100%;
}
/* Для блока слева */
.animate__slideInLeft {
    -webkit-animation-duration: 1s;
            animation-duration: 1s; /* Длительность анимации */
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s; /* Задержка перед началом анимации */
}

/* Для блока справа */
.animate__slideInRight {
    -webkit-animation-duration: 1s;
            animation-duration: 1s; /* Длительность анимации */
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s; /* Задержка перед началом анимации */
}

.WelcomePage {
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;



}
.Info {
    display: flex;
    margin-top: 1%;
    overflow: hidden; /* Скрывает содержимое, выходящее за пределы контейнера */
    flex: 1 1;
    justify-content: center;
    align-items: center;

}

.Info-button {
    display: flex;
    justify-content: space-between;
}

.Info-left {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    padding: 0 0 0 5%; /* Здесь установите отступ слева на 100px */
}

.Info-right {
    flex: 1 1;
    display: flex;
    align-items: center;
    width: 50%;
margin-left: -35%;

}

.Info-text {
    color: white;
    font-size: 45px;
    font-weight: 700;
    font-family: 'Montserrat ExtraBold', sans-serif;
    width: 60%;
    line-height: 1;
}


.registration {
    margin-top: 25px;
    width: 60%;
    height: 100%;
}

.come {
    padding: 5px 25px;
    border-radius: 41px;
    grid-gap: 10px;
    gap: 10px;
    color: #00B9F8;
    cursor: pointer;
    border: none;
    margin-right: 25px;
    font-family: 'Montserrat SemiBold', sans-serif;
    font-size: 20px;
    flex-flow: wrap;
    justify-content: center;
    white-space: nowrap;
}


.hovered {
    background-color: #ff9000!important;
}
.Become-a-member {
    padding: 5px 35px;
    border-radius: 41px;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    border: none;
    background: linear-gradient(90.09deg, #7359EA -1.33%, #AE61FF 41.19%, #7359EA 99.93%);
    color: white;
    font-family: 'Montserrat SemiBold', sans-serif;
    font-size: 20px;
    flex-flow: wrap;
    justify-content: center;
    white-space: nowrap;
}

.Become-a-member:hover {
    background: #ff9000;
    transition: 1.5s;
}






.Info-button {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #3C3C56;
    color: white;
}

.Company-name {
    color: gray;
}

.Untitled img {
    width: 150%;
    max-width: 1250px;
}

.Untitled {
    width: 80%;
    display: contents;
    z-index: 1;
    flex-direction: column;

}

.mobpkimg {
    display: none;
}

@media  (min-width: 2000px) {

    .Info-text {
        font-size: 60px;
    }

}
@media (max-width: 1024px) {
    .Info {
        margin-top: 80px;
    }
    .registration {

        width: 60%;

    }
}

@media (max-width: 1245px) {
    .Info {
        margin-top: 40px;
    }
}
@media (max-width: 850px) {
    .WelcomePage {
        display: flex;
        flex-direction: inherit;
        justify-content: space-between;
    }
    .Info {
        flex-direction: column; /* Отображать блоки вертикально */
        align-items: center;
        margin: 80px 20px 20px 20px;
    }

    .Info-left {
        width: 100%; /* Один блок занимает всю ширину */
        display: block;

    }
    .Untitled {
        width: 100%;
    }
    .Company-name {
        font-size: 10px;
    }
    .Info-right {
        width: 100%; /* Один блок занимает всю ширину */
        margin-left: 0;
    }

    .Info-button {
        flex-direction: column;
        padding: 10px;

    }

    .come {
        padding: 5px 25px;
        margin: 10px;
        font-family: 'Montserrat SemiBold', sans-serif;
        font-size: 3vw;
    }

    .Become-a-member {
        padding: 5px 35px;
        margin: 10px;
        font-family: 'Montserrat SemiBold', sans-serif;
        font-size: 3vw;
    }


    .Info-text {
        color: white;
        font-size: 20px;
        font-weight: 700;
        width: 100%;

    }

    .registration {
        display: flex;
        width: 100%;
    }

    .Untitled img {
        width: 100%;
    }

    .mobpkimg {
        display: block;
    }

    .fullpkimg {
        display: none;
    }
}
@media (max-width: 768px) and (orientation: landscape) {
    .Info {
        display: flex;
        justify-content: space-between;
        overflow: hidden; /* Скрывает содержимое, выходящее за пределы контейнера */
        flex-direction: row;
    }

    .Untitled {
        width: 100%;
    }

    .Info-left {
        width: 50%;
        padding: 0 20px;
    }

    .Info-right {
        width: 50%;
        margin-left: 0;
    }

    .Info-button {
        flex-direction: column;
        padding: 10px;
    }

    .come {
        width: 30%;
        margin: 10px;
        font-size: 10px;
    }

    .Become-a-member {
        width: 40%;
        margin: 10px;
        font-size: 10px;
    }


    .Info-text {
        color: white;
        font-size: 15px;
        font-weight: 700;

    }

    .registration {
        display: flex;
    }

    .Untitled img {
        width: 100%;
    }

    .mobpkimg {
        display: block;
    }

    .fullpkimg {
        display: none;
    }
}
@media (max-width: 760px) and (orientation: landscape) {
    .Info {
        display: flex;
        justify-content: space-between;
        overflow: hidden; /* Скрывает содержимое, выходящее за пределы контейнера */
        flex-direction: row;
    }

    .Untitled {
        width: 100%;
    }

    .Info-left {
        width: 50%;
        padding: 0 20px;
    }

    .Info-right {
        width: 50%;
        margin-left: 0;
    }

    .Info-button {
        flex-direction: column;
        padding: 10px;
    }

    .come {
        width: 30%;
        margin: 10px;
        font-size: 10px;
    }

    .Become-a-member {
        width: 40%;
        margin: 10px;
        font-size: 10px;
    }


    .Info-text {
        color: white;
        font-size: 15px;
        font-weight: 700;

    }

    .registration {
        display: flex;
        width: 100%;
    }

    .Untitled img {
        width: 75%;
    }

    .mobpkimg {
        display: block;
    }

    .fullpkimg {
        display: none;
    }

}


.formlogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
align-items: center;
    font-family: 'Montserrat Bold', sans-serif;
}
.formlogin h1 {
    font-size: 27px
}
.helppassword {
    align-items: center;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    text-align: initial;
    grid-gap: 10%;
    gap: 10%;
    width: 366px;
}

.Show {
    margin-right: 20px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
}
.showPassword {
    font-family: 'Montserrat Regular', sans-serif;

}

.forget {
    text-decoration: none;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 12px;
}

:hover.forget {
    color: #4d4d4d;
}

.buttonsubmit {
    margin-top: 20px;
}

.buttonsubmit button {
    border: none;
    border-radius: 41px;
    padding: 5px 25px;
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    color: white;
    cursor: pointer;
    font-size: 20px;
    flex-flow: wrap;
    justify-content: center;
    white-space: nowrap;
    font-family: 'Montserrat SemiBold', sans-serif;
    flex-flow: wrap;
}

.buttonsubmit button:hover {
    background: #ff9000;
    transition: 1.5s;
}

.label-for-showPassword {
    display: inline-block;
    width: 30px; /* Задайте желаемую ширину и высоту для создания круглой формы */
    height: 30px;
    border-radius: 50%; /* Делаем круглой */
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    color: white; /* Цвет текста */
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

input[type="checkbox"] {
    display: none;
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
}

input[type="checkbox"] + label {
    position: relative;
    padding-left: 20px; /* Размер круглой области */
    cursor: pointer;
    padding-top: 2px;
    font-family: 'Montserrat Regular', sans-serif;
    touch-action: manipulation;
    font-size: 12px;
}
input[type="text"], input[type="password"] {
    border: none;
    border-radius: 999px;
    padding: 13px;
    width: 313px;
    background: #E8E6FF;
    text-align: start;
    font-family: 'Montserrat Regular', sans-serif;
    outline: none;
}

input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 2px;
    width: 15px;
    height: 15px;
    border-radius: 50%; /* Превращаем в круг */
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
}

/* Отображаем галочку при отмеченном чекбоксе */
input[type="checkbox"]:checked + label::before {
    content: "\2713"; /* Юникод-символ галочки */
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;

}
.login, .password {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    background: #E8E6FF;
    border-radius: 999px;
}
.logimg {
    padding-left: 10px;
}

.login input {

    background-position: 10px center;
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
    padding-left: 10px;

}


.password input[type="password"] {

    background-position: 10px center;
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;

}

.password input[type="text"] {

    background-position: 10px center;

    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
}
.submit {
    font-family: 'Montserrat Bold', sans-serif;
}

@media (max-width: 600px) {
    .formlogin {
        margin: 15px;
    }

    .login, .password {
        margin: 10px;
    }

    input[type="text"], input[type="password"] {
        width: 135px;
        touch-action: manipulation;
        height: 0;
    }
    .password input[type="password"] {
        background-size: 10px;

    }
    .login input {

        background-size: 10px; /* Размер изображения */


    }
    .password input[type="text"] {
        background-size: 10px;
    }
    input[type="checkbox"] + label {

        padding-left: 10px;

        padding-top: 0;
    }

    .helppassword {

        width:135px;
    }

    .formlogin h1 {
        font-size:20px;
    }
    .label-for-showPassword {
        display: none;
    }

    input[type="checkbox"] + label {

        font-size: 10px;
    }
    .forget {

        font-size: 10px;;
    }
    .buttonsubmit button {

        font-size: 15px;

    }

    input[type="checkbox"] + label::before {

        left: -15px;

    }

}

@media (max-width: 1024px) and (orientation: landscape) {
    .buttonsubmit button {

        padding: 5px 30px;

    }

    .login, .password {
        margin: 5px;
    }

    input[type="text"], input[type="password"] {

        padding: 10px;
    }

    .formlogin {
        margin-top: 0;
        margin-bottom: 0;
    }
}
.inforeg {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    align-items: center;

}
.textreg {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: initial;
    width: 366px;

}
.center {
    text-align: center;
    font-family: 'Montserrat Bold', sans-serif;
}
.center h1 {
    font-size: 27px
}
.agreements {

    width: 75%;
    align-items: start;
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 10px;
}

.forgetreg {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 10px;

}

.helppasswordreg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat Regular', sans-serif;
    font-size: 12px;
    width: 100%;
    grid-gap: 65px;
    gap: 65px
}

.loginreg {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
}

.loginregagreements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    font-size: 10px;
    width: 98%;

}

/* Стили для инпута и лейбла */
.loginregagreements input[type="checkbox"] {
    display: none;
}

.loginregagreements input[type="checkbox"] + label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    text-align: start;
}

/* Стили для круглой области */
.loginregagreements input[type="checkbox"] + label::before {
    content: "";
    width: 15px; /* Размер круглой области */
    height: 15px; /* Размер круглой области */
    border-radius: 50%; /* Превращаем в круг */
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    margin-right: 10px; /* Расстояние между круглой областью и текстом */
    margin-top: 5px;
}

/* Стили для галочки при отмеченном чекбоксе */
.loginregagreements input[type="checkbox"]:checked + label::before {

    content: "\2713"; /* Юникод-символ галочки */
    text-align: center;
    font-size: 12px; /* Размер символа */
    line-height: 15px; /* Выравнивание символа по вертикали */
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
}

.loginregagreements .agreements {
    flex: 1 1; /* Занимает оставшееся пространство, чтобы текст занимал всю доступную ширину */
    font-size: 12px;
}
.isAgreed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    font-size: 10px;
    width: 98%;

}

/* Стили для инпута и лейбла */
.isAgreed input[type="checkbox"] {
    display: none;
}

.isAgreed input[type="checkbox"] + label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    text-align: start;
}

/* Стили для круглой области */
.isAgreed input[type="checkbox"] + label::before {
    content: "";
    width: 15px; /* Размер круглой области */
    height: 15px; /* Размер круглой области */
    border-radius: 50%; /* Превращаем в круг */
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    margin-right: 10px; /* Расстояние между круглой областью и текстом */
    margin-top: 5px;
}

/* Стили для галочки при отмеченном чекбоксе */
.isAgreed input[type="checkbox"]:checked + label::before {

    content: "\2713"; /* Юникод-символ галочки */
    text-align: center;
    font-size: 12px; /* Размер символа */
    line-height: 15px; /* Выравнивание символа по вертикали */
    touch-action: manipulation;
    font-family: 'Montserrat Regular', sans-serif;
}

.isAgreed .agreements {
    flex: 1 1; /* Занимает оставшееся пространство, чтобы текст занимал всю доступную ширину */
    font-size: 12px;
}
.landing-modal-form-message1 {
    font-size: 18px;
    font-weight: 400;
margin-left: 20px;
    width: 340px;
    text-align: start;
}
.landing-modal-form-message1.success {
    color: green;
}
.landing-modal-form-message1.error {
    color: red;
}
.loginreg input[type="text"] {
    border: none;
    border-radius: 999px;
    padding: 13px;
    width: 340px;
    background: #E8E6FF;
    text-align: start;
    font-family: 'Montserrat Regular', sans-serif;
}

.loginreg input[type="password"] {
    border: none;
    border-radius: 999px;
    padding: 13px;
    width: 340px;
    background: #E8E6FF;
    text-align: start;
    font-family: 'Montserrat Regular', sans-serif;
}

.formloginreg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
    align-items: center;
}
.buttonsubmitreg{
    text-align: center;
    margin-top: 15px;
}
/*.buttonsubmitreg button {*/
/*    border: none;*/
/*    border-radius: 41px;*/
/*    padding: 5px 25px;*/
/*    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);*/
/*    color: white;*/
/*    cursor: pointer;*/
/*    font-size: 20px;*/
/*    flex-flow: wrap;*/
/*    justify-content: center;*/
/*    white-space: nowrap;*/
/*    font-family: 'Montserrat SemiBold', sans-serif;*/
/*    flex-flow: wrap;*/
/*}*/

/*.buttonsubmitreg button:hover {*/
/*    background: #ff9000;*/
/*    transition: 1.5s;*/
/*}*/

.buttonsubmitreg input {
    border: none;
    border-radius: 41px;
    padding: 5px 25px;
    background: linear-gradient(90deg, #7B6CEA 9.12%, #AE61FF 42.93%, #7359EA 94.89%);
    color: white;
    cursor: pointer;
    font-size: 20px;
    flex-flow: wrap;
    justify-content: center;
    white-space: nowrap;
    font-family: 'Montserrat SemiBold', sans-serif;
    flex-flow: wrap;
}

.buttonsubmitreg input:hover {
    background: #ff9000;
    transition: 1.5s;
}

.loginregagreements input[type="checkbox"] + label {
    padding-left: 20px;
}
@media (max-width: 600px) {
    .center {
        text-align: center;
    }
    .loginreg, .loginregagreements {
        margin: 10px; /* Уменьшаем отступы для мобильных устройств */
    }
    .center h1 {
        font-size: 20px;
    }
    .agreements {

        font-size: 14px; /* Уменьшаем размер шрифта */
    }

    .helppasswordreg {
        grid-gap: 10px;
        gap: 10px; /* Уменьшаем горизонтальный отступ между элементами */
        font-size: 8px; /* Уменьшаем размер шрифта */
        margin: 10px;
    }

    .forgetreg {
        font-size: 8px; /* Уменьшаем размер шрифта */
    }

    .loginreg input[type="text"],
    .loginreg input[type="password"] {
        text-align: left; /* Левое выравнивание текста */
         font-size: 10px; /* Уменьшаем размер шрифта */
        padding: 10px; /* Уменьшаем внутренний отступ */
        width: 135px;

    }


    .loginregagreements .agreements {
        flex: 1 1;
        font-size: 10px;
    }
    .buttonsubmitreg {
        text-align: center;
    }
    .loginregagreements input[type="checkbox"] + label {

        font-size: 10px;

    }

    .textreg {

        width: 50%;

    }

    .loginregagreements input[type="checkbox"] + label {
        padding-left: 10px;
    }

    .buttonsubmitreg button {

        font-size: 15px;

    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    .center {
        text-align: center;
    }
    .loginreg input[type="text"], .loginreg input[type="password"] {
        text-align: left;
        font-size: 6px;
        padding: 6px;
    }

    .formloginreg {
        justify-content: center;

        margin-top: 0;
        margin-bottom: 0;
        margin-right: 20px;
        margin-left: 20px;
    }

    .formloginreg h2 {
        font-size: 10px;
    }
    .buttonsubmitreg {
        text-align: center;
    }


    .helppasswordreg {
        grid-gap: 10px;
        gap: 10px; /* Уменьшаем горизонтальный отступ между элементами */
        font-size: 8px; /* Уменьшаем размер шрифта */
        margin: 0;
    }

    .loginreg {
        display: flex;
        margin-top: 10px;
        margin-bottom: 5px;

    }
}
/* Common */
.landing-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    padding: 54px 40px;
}
.landing-modal-cross {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
    width: 40px;
    height: 40px;
    opacity: 0.5;
}
.landing-modal-cross:hover {
    opacity: 1;
}
.landing-modal-header {
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
}
.landing-modal-header > span {
    border-bottom: 1px solid #000;
}
.landing-modal-form {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    font-size: 16px;
    font-weight: 400;
}
.landing-modal-form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.landing-modal-form-note {
    font-style: italic;
}
.landing-modal-form-message {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    width: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}
.landing-modal-form-message.success {
    color: green;
}
.landing-modal-form-message.error {
    color: red;
}
.landing-modal-form-input {
    width: 100%;
    height: 48px;
    line-height: 1.6;
    font-size: 18px;
    font-weight: 400;
    box-shadow: 0 1px 0 0 #000;
    border: none;
    outline: none;
}
.landing-modal-form-checkbox > label {
    display: flex;
    align-items: flex-start;
    grid-gap: 4px;
    gap: 4px;
}
.landing-modal-form-checkbox input,
.landing-modal-form-checkbox label {
    cursor: pointer;
}
.landing-modal-form-controls {
    display: flex;
    justify-content: flex-end;
    font-size: 32px;
    font-weight: 400;
}
.landing-modal-form-controls > input {
    margin-bottom: 0;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
}
.landing-modal-form-controls > input:disabled {
    cursor: not-allowed;
    color: rgba(16, 16, 16, 0.3);
}
.landing-modal-login-kundelik {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
    width: 100%;
    padding: 8px 12px;
    background-color: gainsboro;
    border-radius: 12px;
    font-family: sans-serif;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.8;
}
.landing-modal-login-kundelik:hover {
    opacity: 1;
}
.landing-modal-login-kundelik > img {
    width: 96px;
}

/* Authentication (login/forgot password) */
.landing-modal-form-toggle-login > span {
    cursor: pointer;
}
.landing-modal-form-toggle-login > span {
    color: #324f7e;
}
.landing-modal-form-toggle-login > span:hover {
    border-bottom: 1px solid #324f7e;
}

/* Authentication (registration) */

/* Other modals */
.landing-modal-about.text,
.landing-modal-manual.text {
    line-height: 24px;
    font-size: 16px;
    text-align: justify;
}
.landing-modal-terms {
    text-align: justify;
}
.landing-modal-about.contacts__list {
    display: flex;
    justify-content: center;
    grid-gap: 24px;
    gap: 24px;
    padding: 0;
    margin: 0;
    list-style: none;
}
.landing-modal-about.contacts__item {
    line-height: 1;
    font-size: 18px;
}
.landing-modal-manual ol {
    margin-top: 0;
    padding-left: 2rem;
}

@media only screen and (max-width: 480px) {
    .landing-modal {
        padding: 40px 32px;
    }
    .landing-modal-cross {
        right: 24px;
        top: 24px;
        width: 32px;
        height: 32px;
    }
    .landing-modal-header {
        font-size: 28px;
        line-height: 28px;
    }
    .landing-modal-form-input {
        height: 36px;
        line-height: 18px;
        font-size: 16px;
    }
    .landing-modal-form-controls {
        font-size: 24px;
    }
}

@media only screen and (max-width: 400px) {
    .landing-modal {
        padding: 36px 24px;
        font-size: 14px;
    }
    .landing-modal-cross {
        right: 20px;
        top: 20px;
        width: 24px;
        height: 24px;
    }
    .landing-modal-header {
        font-size: 22px;
        line-height: 22px;
    }
    .landing-modal-form-input {
        height: 32px;
        font-size: 14px;
        line-height: 14px;
    }
    .landing-modal-form-controls {
        font-size: 20px;
    }
    .landing-modal-about .contacts__list {
        grid-gap: 16px;
        gap: 16px;
    }
    .landing-modal-about .contacts__item {
        font-size: 14px;
    }
}
/*
 * CKEditor 5 (v31.0.0) content styles.
 * Generated on Thu, 28 Oct 2021 07:37:40 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}

/* custom code */
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ck-content {
  line-height: normal !important;
  white-space: pre-wrap;
  tab-size: 4;
}

.ck-content pre {
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
}
.ck-content pre code {
  display: block;
  position: relative;
  padding: 1rem !important;
  color: #353535;
  background: hsla(0,0%,78%,.3);
  border: 1px solid #c4c4c4;
  border-radius: 2px !important;
  line-height: 1.35;
  font-size: 17px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.ck-content pre:after {
  content: none !important;
}
.ck-content .alert-yellow {
  color: #664d03;
  background-color: #fff3cd !important;
  border-color: #ffecb5;
}
.ck-content .alert-green {
  color: #0f5132;
  background-color: #d1e7dd !important;
  border-color: #badbcc;
}
.ck-content .alert-red {
  color: #842029;
  background-color: #f8d7da !important;
  border-color: #f5c2c7;
}
.ck-content .alert-blue {
  color: #084298;
  background-color: #cfe2ff !important;
  border-color: #b6d4fe;
}
.ck-content .alert-ltblue {
  color: #055160;
  background-color: #cff4fc !important;
  border-color: #b6effb;
}
.ck-content .alert-grey {
  color: #41464b;
  background-color: #e2e3e5!important;
  border-color: #d3d6d8;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - 1.5em);
  max-width: calc(100% - var(--ck-image-style-spacing));
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: 1.5em;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: 1.5em;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: 1.5em;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
  margin-top: 0;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: calc(1.5em / 2);
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: calc(1.5em / 2);
  margin-bottom: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: calc(1.5em / 2);
  margin-right: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: calc(1.5em / 2);
  margin-left: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: hsl(60, 97%, 73%);
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: hsl(120, 93%, 68%);
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: hsl(345, 96%, 73%);
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: hsl(201, 97%, 72%);
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: hsl(0, 85%, 49%);
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: hsl(112, 100%, 27%);
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  color: var(--ck-color-image-caption-text);
  background-color: hsl(0, 0%, 97%);
  background-color: var(--ck-color-image-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  /* margin: 0.9em auto; */
  min-width: 50px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
   * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
   * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
   * This strange behavior does not happen with inline-flex.
   */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir='rtl'] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: hsl(0, 0%, 20%);
  color: var(--ck-color-table-caption-text);
  background-color: hsl(0, 0%, 97%);
  background-color: var(--ck-color-table-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
  max-width: 100%;
  overflow: auto;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir='rtl'] .table th {
  text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir='ltr'] .table th {
  text-align: left;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: '';
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  user-select: none;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: 16px;
  width: var(--ck-todo-list-checkmark-size);
  height: 16px;
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: '';
  left: calc(16px / 3);
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(16px / 5.3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(16px / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(16px / 2.6);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(16px / 8) calc(16px / 8) 0;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  /* margin: 15px 0; */
  margin-top: -10px;
  margin-bottom: 15px;
  /* height: 4px; */
  height: 2px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
  font-style: italic;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: hsla(341, 100%, 30%, 0.1);
  background: var(--ck-color-mention-background);
  color: hsl(341, 100%, 30%);
  color: var(--ck-color-mention-text);
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}


:root {
  --opacity: 1;
  --color: 28, 41, 61;
  --radius: 5px;
}

.cs-square {--radius: 0;}
.cs-rounded {--radius: 10px;}
.cs-rounded-full {--radius: 9999px;}

.ck-content .cs-exercise,
.ck-content .cs-exercise-2,
.ck-content .cs-exercise-3,
.ck-content .cs-definition,
.ck-content .cs-definition-2,
.ck-content .cs-definition-3,
.ck-content .cs-color,
.ck-content .cs-square,
.ck-content .cs-rounded,
.ck-content .cs-rounded-full,
.ck-content .cs-icon {
  border: none;
  font-style: initial;
  position: relative;
  overflow: initial;
}

.ck-content .cs-exercise > .table,
.ck-content .cs-exercise > ul,
.ck-content .cs-exercise > ol,
.ck-content .cs-exercise-2 > .table,
.ck-content .cs-exercise-2 > ul,
.ck-content .cs-exercise-2 > ol,
.ck-content .cs-exercise-3 > .table,
.ck-content .cs-exercise-3 > ul,
.ck-content .cs-exercise-3 > ol,
.ck-content .cs-definition > .table,
.ck-content .cs-definition > ul,
.ck-content .cs-definition > ol,
.ck-content .cs-definition-2 > .table,
.ck-content .cs-definition-2 > ul,
.ck-content .cs-definition-2 > ol,
.ck-content .cs-definition-3 > .table,
.ck-content .cs-definition-3 > ul,
.ck-content .cs-definition-3 > ol,
.ck-content .cs-color > .table,
.ck-content .cs-color > ul,
.ck-content .cs-color > ol,
.ck-content .cs-square > .table,
.ck-content .cs-square > ul,
.ck-content .cs-square > ol,
.ck-content .cs-rounded > .table,
.ck-content .cs-rounded > ul,
.ck-content .cs-rounded > ol,
.ck-content .cs-rounded-full > .table,
.ck-content .cs-rounded-full > ul,
.ck-content .cs-rounded-full > ol,
.ck-content .cs-icon > .table,
.ck-content .cs-icon > ul,
.ck-content .cs-icon > ol {
  margin-top: 0;
}

.ck-content .cs-exercise {
  --opacity: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 40px;
  padding: 8px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: initial;
  border-left: 1px solid rgba(28, 41, 61, 1);
  border-left: 1px solid rgba(var(--color), var(--opacity));
}

.ck-content .cs-exercise::after {
  --opacity: 1;
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background: rgba(28, 41, 61, 1);
  background: rgba(var(--color), var(--opacity));
  display: block;
  position: absolute;
  left: -4px;
  bottom: -5px;
}

.ck-content .cs-exercise-2 {
  --opacity: 0.1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 40px;
  padding: 8px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  border-radius: var(--radius);
  overflow: initial;
  background: rgb(28, 41, 61, 1);
  background: rgb(var(--color), var(--opacity));
}

.ck-content .cs-exercise-2.cs-exercise-3 {
  --opacity: 0.1;
}
.ck-content .cs-exercise-2.cs-definition-3 {
  border-color: rgb(28, 41, 61);
  border-color: rgb(var(--color));
}
.ck-content .cs-exercise-2.cs-definition-3.cs-icon::before,
.ck-content .cs-exercise-2.cs-exercise-3.cs-icon::before {
  outline: 3px solid #fff!important;
}
.ck-content .cs-exercise-2.cs-definition-3,
.ck-content .cs-exercise.cs-definition-3 {
  padding: 16px!important;
}
.ck-content .cs-exercise.cs-exercise-2::after,
.ck-content .cs-exercise.cs-definition-3::after {
  display: none;
}

.ck-content .cs-exercise.cs-icon.cs-icon::before,
.ck-content .cs-exercise-2.cs-icon.cs-icon::before,
.ck-content .cs-exercise-3.cs-icon.cs-icon::before {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  top: -16px;
  left: -16px;
  outline: 3px solid #fff;
  box-shadow: 0 0 0 4px rgb(28, 41, 61);
  box-shadow: 0 0 0 4px rgb(var(--color));
}
.ck-content .cs-exercise-2.cs-icon.cs-icon::before {
  outline: none;
}

.ck-content .cs-exercise-3 {
  --opacity: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 40px;
  padding: 8px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  border-radius: var(--radius);
  overflow: initial;
  border: 3px solid rgb(28, 41, 61);
  border: 3px solid rgb(var(--color));
}

.ck-content .cs-definition {
  box-shadow: rgb(28 41 61 / 5%) 0px 0px 20px, rgb(28 41 61 / 6%) 0px 12px 20px;
  width: 100%;
  min-height: 72px;
  border-radius: 5px;
  border-radius: var(--radius);
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ck-content .cs-definition.cs-color {
  --opacity: 0.1;
  background: rgba(28, 41, 61, 1);
  background: rgba(var(--color), var(--opacity));
  box-shadow: none!important;
}

.ck-content .cs-definition-2,
.ck-content .cs-definition-3 {
  width: 100%;
  min-height: 72px;
  border-radius: 5px;
  border-radius: var(--radius);
  padding: 16px;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 0px 3px rgba(28, 41, 61, 1);
  box-shadow: inset 0px 0px 0px 3px rgba(var(--color), var(--opacity));
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ck-content .cs-definition.cs-rounded-full,
.ck-content .cs-definition-2.cs-rounded-full,
.ck-content .cs-definition-3.cs-rounded-full {
  padding: 20px 80px;
  border-radius: 99px;
}
.ck-content .cs-definition-3 {
  box-shadow: none;
  border: 2px dashed rgba(28, 41, 61, 1);
  border: 2px dashed rgba(var(--color), var(--opacity));
}

.ck-content .cs-definition.cs-icon,
.ck-content .cs-definition-2.cs-icon,
.ck-content .cs-definition-3.cs-icon {
  padding: 16px 16px 16px 72px;
}

.ck-content .cs-exercise-3.cs-definition.cs-icon {
  padding: 16px;
}

.ck-content .cs-exercise.cs-definition::after {
  display: none;
}
.ck-content .cs-exercise.cs-definition {
  border-width: 5px;
  border-color: rgba(28, 41, 61, 1);
  border-color: rgba(var(--color), 1);
}
.ck-content .cs-exercise.cs-definition.cs-icon {
  padding: 16px;
}

.cs-info {--color: 76, 153, 230;}
.cs-success {--color: 0, 211, 89;}
.cs-warning {--color: 255, 182, 8;}
.cs-error {--color: 255, 12, 12;}
.cs-magenta {--color: 189, 51, 164;}
.cs-indigo {--color: 75, 0, 130;}
.cs-purple {--color: 148, 0, 211;}
.cs-brown {--color: 121, 68, 59;}
.cs-orange {--color: 237, 118, 14;}
.cs-green {--color: 0, 138, 0;}
.cs-mint {--color: 62, 180, 137;}
.cs-blue {--color: 31, 58, 147;}
.cs-grey {--color: 128, 128, 128;}
.cs-softcyan {--color: 120, 219, 226;}
.cs-burgundy {--color: 176, 0, 0;}
.cs-pink {--color: 255, 44, 129;}
.cs-standart {--color: 28, 41, 61;}
.cs-peru {--color: 222, 170, 136;}

.ck-content .cs-definition.cs-icon::before,
.ck-content .cs-definition-2.cs-icon::before,
.ck-content .cs-definition-3.cs-icon::before {
  top: 16px;
  left: 16px;
}

.cs-icon::before {
  --opacity: 1;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M20 20a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9zM9 10v6h6v-6H9zm2 2h2v2h-2v-2z'/%3E%3C/svg%3E"); 
  background-color: rgba(28, 41, 61, 1); 
  background-color: rgba(var(--color), var(--opacity));;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: -5px;
  left: -20px;
}

.cs-icon.ic-mail::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M2 5.5V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9zM0 10h5v2H0v-2zm0 5h8v2H0v-2z'/%3E%3C/svg%3E");
}
.cs-icon.ic-attach::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M14 13.5V8a4 4 0 1 0-8 0v5.5a6.5 6.5 0 1 0 13 0V4h2v9.5a8.5 8.5 0 1 1-17 0V8a6 6 0 1 1 12 0v5.5a3.5 3.5 0 0 1-7 0V8h2v5.5a1.5 1.5 0 0 0 3 0z'/%3E%3C/svg%3E");
}
.cs-icon.ic-award::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17 15.245v6.872a.5.5 0 0 1-.757.429L12 20l-4.243 2.546a.5.5 0 0 1-.757-.43v-6.87a8 8 0 1 1 10 0zm-8 1.173v3.05l3-1.8 3 1.8v-3.05A7.978 7.978 0 0 1 12 17a7.978 7.978 0 0 1-3-.582zM12 15a6 6 0 1 0 0-12 6 6 0 0 0 0 12z'/%3E%3C/svg%3E");
}
.cs-icon.ic-chart::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M3 12h4v9H3v-9zm14-4h4v13h-4V8zm-7-6h4v19h-4V2z'/%3E%3C/svg%3E");
}
.cs-icon.ic-bubble::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0L24 0 24 24 0 24z'/%3E%3Cpath d='M16 16c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM6 12c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm8.5-10C17.538 2 20 4.462 20 7.5S17.538 13 14.5 13 9 10.538 9 7.5 11.462 2 14.5 2z'/%3E%3C/svg%3E");
}
.cs-icon.ic-bookmark::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5 2h14a1 1 0 0 1 1 1v19.143a.5.5 0 0 1-.766.424L12 18.03l-7.234 4.536A.5.5 0 0 1 4 22.143V3a1 1 0 0 1 1-1z'/%3E%3C/svg%3E");
}
.cs-icon.ic-calendar::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zM4 9v10h16V9H4zm2 2h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z'/%3E%3C/svg%3E");
}
.cs-icon.ic-flag::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M2 3h19.138a.5.5 0 0 1 .435.748L18 10l3.573 6.252a.5.5 0 0 1-.435.748H4v5H2V3z'/%3E%3C/svg%3E");
}
.cs-icon.ic-link::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.06 8.11l1.415 1.415a7 7 0 0 1 0 9.9l-.354.353a7 7 0 0 1-9.9-9.9l1.415 1.415a5 5 0 1 0 7.071 7.071l.354-.354a5 5 0 0 0 0-7.07l-1.415-1.415 1.415-1.414zm6.718 6.011l-1.414-1.414a5 5 0 1 0-7.071-7.071l-.354.354a5 5 0 0 0 0 7.07l1.415 1.415-1.415 1.414-1.414-1.414a7 7 0 0 1 0-9.9l.354-.353a7 7 0 0 1 9.9 9.9z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-print::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M7 17h10v5H7v-5zm12 3v-5H5v5H3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-2zM5 10v2h3v-2H5zm2-8h10a1 1 0 0 1 1 1v3H6V3a1 1 0 0 1 1-1z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-chat::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M7.291 20.824L2 22l1.176-5.291A9.956 9.956 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.956 9.956 0 0 1-4.709-1.176z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-chat-2::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-message::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M2 8.994A5.99 5.99 0 0 1 8 3h8c3.313 0 6 2.695 6 5.994V21H8c-3.313 0-6-2.695-6-5.994V8.994zM14 11v2h2v-2h-2zm-6 0v2h2v-2H8z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-edit::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M9.243 19H21v2H3v-4.243l9.9-9.9 4.242 4.244L9.242 19zm5.07-13.556l2.122-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-quil::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M21 2C6 2 4 16 3 22h1.998c.666-3.333 2.333-5.166 5.002-5.5 4-.5 7-4 8-7l-1.5-1 1-1c1-1 2.004-2.5 3.5-5.5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-pen::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M4.929 21.485l5.846-5.846a2 2 0 1 0-1.414-1.414l-5.846 5.846-1.06-1.06c2.827-3.3 3.888-6.954 5.302-13.082l6.364-.707 5.657 5.657-.707 6.364c-6.128 1.414-9.782 2.475-13.081 5.303l-1.061-1.06zM16.596 2.04l6.347 6.346a.5.5 0 0 1-.277.848l-1.474.23-5.656-5.656.212-1.485a.5.5 0 0 1 .848-.283z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-markup::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm5.051-3.796l-.862-3.447a1 1 0 0 0-.97-.757H8.781a1 1 0 0 0-.97.757l-.862 3.447A7.967 7.967 0 0 0 12 20a7.967 7.967 0 0 0 5.051-1.796zM10 12h4v-1.5l-1.038-3.635a1 1 0 0 0-1.924 0L10 10.5V12z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-ruler::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17 19h2v-5h-9V5H5v2h2v2H5v2h3v2H5v2h2v2H5v2h2v-2h2v2h2v-3h2v3h2v-2h2v2zm-5-7h8a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v8z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-pen-ruler::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.636 12.707l1.828 1.829L8.88 13.12 7.05 11.293l1.414-1.414 1.829 1.828 1.414-1.414L9.88 8.464l1.414-1.414L13.12 8.88l1.415-1.415-1.829-1.828 2.829-2.828a1 1 0 0 1 1.414 0l4.242 4.242a1 1 0 0 1 0 1.414L8.464 21.192a1 1 0 0 1-1.414 0L2.808 16.95a1 1 0 0 1 0-1.414l2.828-2.829zm8.485 5.656l4.243-4.242L21 16.757V21h-4.242l-2.637-2.637zM5.636 9.878L2.807 7.05a1 1 0 0 1 0-1.415l2.829-2.828a1 1 0 0 1 1.414 0L9.88 5.635 5.636 9.878z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-palette::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 2c5.522 0 10 3.978 10 8.889a5.558 5.558 0 0 1-5.556 5.555h-1.966c-.922 0-1.667.745-1.667 1.667 0 .422.167.811.422 1.1.267.3.434.689.434 1.122C13.667 21.256 12.9 22 12 22 6.478 22 2 17.522 2 12S6.478 2 12 2zM7.5 12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm9 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM12 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-magic::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M15.224 15.508l-2.213 4.65a.6.6 0 0 1-.977.155l-3.542-3.739a.6.6 0 0 0-.357-.182l-5.107-.668a.6.6 0 0 1-.449-.881l2.462-4.524a.6.6 0 0 0 .062-.396L4.16 4.86a.6.6 0 0 1 .7-.7l5.063.943a.6.6 0 0 0 .396-.062l4.524-2.462a.6.6 0 0 1 .881.45l.668 5.106a.6.6 0 0 0 .182.357l3.739 3.542a.6.6 0 0 1-.155.977l-4.65 2.213a.6.6 0 0 0-.284.284zm.797 1.927l1.414-1.414 4.243 4.242-1.415 1.415-4.242-4.243z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-tools::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.33 3.271a3.5 3.5 0 0 1 4.472 4.474L20.647 18.59l-2.122 2.121L7.68 9.867a3.5 3.5 0 0 1-4.472-4.474L5.444 7.63a1.5 1.5 0 1 0 2.121-2.121L5.329 3.27zm10.367 1.884l3.182-1.768 1.414 1.414-1.768 3.182-1.768.354-2.12 2.121-1.415-1.414 2.121-2.121.354-1.768zm-7.071 7.778l2.121 2.122-4.95 4.95A1.5 1.5 0 0 1 3.58 17.99l.097-.107 4.95-4.95z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-grid::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M22 12.999V20a1 1 0 0 1-1 1h-8v-8.001h9zm-11 0V21H3a1 1 0 0 1-1-1v-7.001h9zM11 3v7.999H2V4a1 1 0 0 1 1-1h8zm10 0a1 1 0 0 1 1 1v6.999h-9V3h8z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-bug::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M6.056 8.3a7.01 7.01 0 0 1 .199-.3h11.49c.069.098.135.199.199.3l2.02-1.166 1 1.732-2.213 1.278c.162.59.249 1.213.249 1.856v1h3v2h-3c0 .953-.19 1.862-.536 2.69l2.5 1.444-1 1.732-2.526-1.458A6.992 6.992 0 0 1 13 21.929V14h-2v7.93a6.992 6.992 0 0 1-4.438-2.522l-2.526 1.458-1-1.732 2.5-1.443A6.979 6.979 0 0 1 5 15H2v-2h3v-1c0-.643.087-1.265.249-1.856L3.036 8.866l1-1.732L6.056 8.3zM8 6a4 4 0 1 1 8 0H8z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-cursor::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.91 12.36L17 20.854l-2.818 1.026-3.092-8.494-4.172 3.156 1.49-14.909 10.726 10.463z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-computer::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M4 16h16V5H4v11zm9 2v2h4v2H7v-2h4v-2H2.992A.998.998 0 0 1 2 16.993V4.007C2 3.451 2.455 3 2.992 3h18.016c.548 0 .992.449.992 1.007v12.986c0 .556-.455 1.007-.992 1.007H13z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-save::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M4 3h13l3.707 3.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm8 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM5 5v4h10V5H5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-mouse::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11.141 2h1.718c2.014 0 3.094.278 4.072.801a5.452 5.452 0 0 1 2.268 2.268c.523.978.801 2.058.801 4.072v5.718c0 2.014-.278 3.094-.801 4.072a5.452 5.452 0 0 1-2.268 2.268c-.978.523-2.058.801-4.072.801H11.14c-2.014 0-3.094-.278-4.072-.801a5.452 5.452 0 0 1-2.268-2.268C4.278 17.953 4 16.873 4 14.859V9.14c0-2.014.278-3.094.801-4.072A5.452 5.452 0 0 1 7.07 2.801C8.047 2.278 9.127 2 11.141 2zM11 6v5h2V6h-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-restart::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M18.537 19.567A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c0 2.136-.67 4.116-1.81 5.74L17 12h3a8 8 0 1 0-2.46 5.772l.997 1.795z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-paper::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M20 2a3 3 0 0 1 3 3v2h-2v12a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3v-2h16v2a1 1 0 0 0 .883.993L18 20a1 1 0 0 0 .993-.883L19 19v-4H3V5a3 3 0 0 1 3-3h14z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-file-list::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M19 22H5a3 3 0 0 1-3-3V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12h4v4a3 3 0 0 1-3 3zm-1-5v2a1 1 0 0 0 2 0v-2h-2zm-2 3V4H4v15a1 1 0 0 0 1 1h11zM6 7h8v2H6V7zm0 4h8v2H6v-2zm0 4h5v2H6v-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-book::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M20 22H6.5A3.5 3.5 0 0 1 3 18.5V5a3 3 0 0 1 3-3h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2v-3H6.5a1.5 1.5 0 0 0 0 3H19zM10 4v8l3.5-2 3.5 2V4h-7z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-align::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M3 4h18v2H3V4zm0 15h14v2H3v-2zm0-5h18v2H3v-2zm0-5h14v2H3V9z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-link::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17.657 14.828l-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829l-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-quote::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-paragraph::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 6v15h-2v-5a6 6 0 1 1 0-12h10v2h-3v15h-2V6h-3zm-2 0a4 4 0 1 0 0 8V6z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-asterisk::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13 3v7.267l6.294-3.633 1 1.732-6.293 3.633 6.293 3.635-1 1.732L13 13.732V21h-2v-7.268l-6.294 3.634-1-1.732L9.999 12 3.706 8.366l1-1.732L11 10.267V3z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-exclamation::before {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M10.5 20.6c0-1 .7-1.7 1.6-1.7 1 0 1.6.7 1.6 1.7 0 .9-.6 1.7-1.6 1.7s-1.6-.7-1.6-1.7zm.4-3.8-.4-14.5h3.2l-.5 14.5h-2.3z'/%3E%3C/svg%3E");
}
.cs-icon.ic-question::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M12 19c.828 0 1.5.672 1.5 1.5S12.828 22 12 22s-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm0-17c3.314 0 6 2.686 6 6 0 2.165-.753 3.29-2.674 4.923C13.399 14.56 13 15.297 13 17h-2c0-2.474.787-3.695 3.031-5.601C15.548 10.11 16 9.434 16 8c0-2.21-1.79-4-4-4S8 5.79 8 8v1H6V8c0-3.314 2.686-6 6-6z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-heart::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M16.5 3C19.538 3 22 5.5 22 9c0 7-7.5 11-10 12.5C9.5 20 2 16 2 9c0-3.5 2.5-6 5.5-6C9.36 3 11 4 12 5c1-1 2.64-2 4.5-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-flask::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M16 2v2h-1v3.243c0 1.158.251 2.301.736 3.352l4.282 9.276c.347.753.018 1.644-.734 1.99-.197.092-.411.139-.628.139H5.344c-.828 0-1.5-.672-1.5-1.5 0-.217.047-.432.138-.629l4.282-9.276C8.749 9.545 9 8.401 9 7.243V4H8V2h8zm-3 2h-2v4h2V4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-tube::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M17 2v2h-1v14c0 2.21-1.79 4-4 4s-4-1.79-4-4V4H7V2h10zm-4 13c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-2-3c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm3-8h-4v4h4V4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-microscope::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M13.196 2.268l3.25 5.63c.276.477.112 1.089-.366 1.365l-1.3.75 1.001 1.732-1.732 1-1-1.733-1.299.751c-.478.276-1.09.112-1.366-.366L8.546 8.215C6.494 8.837 5 10.745 5 13c0 .625.115 1.224.324 1.776C6.1 14.284 7.016 14 8 14c1.684 0 3.174.833 4.08 2.109l7.688-4.439 1 1.732-7.878 4.549c.072.338.11.69.11 1.049 0 .343-.034.677-.1 1H21v2l-17 .001c-.628-.836-1-1.875-1-3.001 0-1.007.298-1.945.81-2.73C3.293 15.295 3 14.182 3 13c0-2.995 1.881-5.551 4.527-6.55l-.393-.682c-.552-.957-.225-2.18.732-2.732l2.598-1.5c.957-.552 2.18-.225 2.732.732z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-virus::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M13.717 1.947l3.734 1.434-.717 1.867-.934-.359-.746 1.945c.779.462 1.444 1.094 1.945 1.846l1.903-.847-.407-.914 1.827-.813 1.627 3.654-1.827.813-.407-.913-1.902.847c.122.477.187.978.187 1.493 0 .406-.04.803-.117 1.187l1.944.746.358-.933 1.868.717-1.434 3.734-1.867-.717.358-.933-1.944-.747c-.462.779-1.094 1.444-1.846 1.945l.847 1.903.914-.407.813 1.827-3.654 1.627-.813-1.827.913-.407-.847-1.902c-.477.122-.978.187-1.493.187-.407 0-.804-.04-1.188-.118l-.746 1.945.934.358-.717 1.868-3.734-1.434.717-1.867.932.358.748-1.944C8.167 16.704 7.502 16.072 7 15.32l-1.903.847.407.914-1.827.813-1.627-3.654 1.827-.813.406.914 1.903-.848C6.065 13.016 6 12.515 6 12c0-.406.04-.803.117-1.187l-1.945-.746-.357.933-1.868-.717L3.381 6.55l1.867.717-.359.933 1.945.747C7.296 8.167 7.928 7.502 8.68 7l-.847-1.903-.914.407-.813-1.827L9.76 2.051l.813 1.827-.913.407.847 1.902C10.984 6.065 11.485 6 12 6c.406 0 .803.04 1.187.117l.745-1.945L13 3.815l.717-1.868zm-3.583 11.285c-.276.478-.112 1.09.366 1.366s1.09.112 1.366-.366.112-1.09-.366-1.366-1.09-.112-1.366.366zM14 11c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm-3.5-1.598c-.478.276-.642.888-.366 1.366.276.478.888.642 1.366.366.478-.276.642-.888.366-1.366-.276-.478-.888-.642-1.366-.366z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-map::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-pushpin::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M18 3v2h-1v6l2 3v2h-6v7h-2v-7H5v-2l2-3V5H6V3z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-rocket::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.33 15.929A13.064 13.064 0 0 1 5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 1.01-.114 1.991-.33 2.929l2.02 1.796a.5.5 0 0 1 .097.63l-2.458 4.096a.5.5 0 0 1-.782.096l-2.254-2.254a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.254 2.254a.5.5 0 0 1-.782-.096l-2.458-4.095a.5.5 0 0 1 .097-.631l2.02-1.796zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-globe::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13 21h5v2H6v-2h5v-1.05a10.002 10.002 0 0 1-7.684-4.988l1.737-.992A8 8 0 1 0 15.97 3.053l.992-1.737A9.996 9.996 0 0 1 22 10c0 5.185-3.947 9.449-9 9.95V21zm-1-4a7 7 0 1 1 0-14 7 7 0 0 1 0 14z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-compass::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm3.5-13.5l-5 2-2 5 5-2 2-5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-anchor::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13 9.874v10.054c3.619-.453 6.487-3.336 6.938-6.972H17L20.704 7A10.041 10.041 0 0 1 22 11.95C22 17.5 17.523 22 12 22S2 17.5 2 11.95c0-1.8.471-3.489 1.296-4.95L7 12.956H4.062c.451 3.636 3.32 6.519 6.938 6.972V9.874A4.002 4.002 0 0 1 12 2a4 4 0 0 1 1 7.874zM12 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-image::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M20 5H4v14l9.292-9.294a1 1 0 0 1 1.414 0L20 15.01V5zM2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-video::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zm8.622 4.422a.4.4 0 0 0-.622.332v6.506a.4.4 0 0 0 .622.332l4.879-3.252a.4.4 0 0 0 0-.666l-4.88-3.252z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-music::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.535V3h8v3h-6v11a4 4 0 1 1-2-3.465z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-headphone::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M4 12h3a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7C2 6.477 6.477 2 12 2s10 4.477 10 10v7a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h3a8 8 0 1 0-16 0z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-mic::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 1a5 5 0 0 1 5 5v4a5 5 0 0 1-10 0V6a5 5 0 0 1 5-5zM3.055 11H5.07a7.002 7.002 0 0 0 13.858 0h2.016A9.004 9.004 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-volume::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.889 16H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3.889l5.294-4.332a.5.5 0 0 1 .817.387v15.89a.5.5 0 0 1-.817.387L5.89 16zm13.517 4.134l-1.416-1.416A8.978 8.978 0 0 0 21 12a8.982 8.982 0 0 0-3.304-6.968l1.42-1.42A10.976 10.976 0 0 1 23 12c0 3.223-1.386 6.122-3.594 8.134zm-3.543-3.543l-1.422-1.422A3.993 3.993 0 0 0 16 12c0-1.43-.75-2.685-1.88-3.392l1.439-1.439A5.991 5.991 0 0 1 18 12c0 1.842-.83 3.49-2.137 4.591z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-notify::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M22 20H2v-2h1v-6.969C3 6.043 7.03 2 12 2s9 4.043 9 9.031V18h1v2zM9.5 21h5a2.5 2.5 0 1 1-5 0z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-repeat::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M6 4h15a1 1 0 0 1 1 1v7h-2V6H6v3L1 5l5-4v3zm12 16H3a1 1 0 0 1-1-1v-7h2v6h14v-3l5 4-5 4v-3z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-star::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 18.26l-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-cog::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.334 4.545a9.99 9.99 0 0 1 3.542-2.048A3.993 3.993 0 0 0 12 3.999a3.993 3.993 0 0 0 3.124-1.502 9.99 9.99 0 0 1 3.542 2.048 3.993 3.993 0 0 0 .262 3.454 3.993 3.993 0 0 0 2.863 1.955 10.043 10.043 0 0 1 0 4.09c-1.16.178-2.23.86-2.863 1.955a3.993 3.993 0 0 0-.262 3.455 9.99 9.99 0 0 1-3.542 2.047A3.993 3.993 0 0 0 12 20a3.993 3.993 0 0 0-3.124 1.502 9.99 9.99 0 0 1-3.542-2.047 3.993 3.993 0 0 0-.262-3.455 3.993 3.993 0 0 0-2.863-1.954 10.043 10.043 0 0 1 0-4.091 3.993 3.993 0 0 0 2.863-1.955 3.993 3.993 0 0 0 .262-3.454zM13.5 14.597a3 3 0 1 0-3-5.196 3 3 0 0 0 3 5.196z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-info::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11v6h2v-6h-2zm0-4v2h2V7h-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-warning::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-alert::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zM11 16v2h2v-2h-2zm0-7v5h2V9h-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-check::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-refresh::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-shield::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M3.783 2.826L12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-eye::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-search::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-share::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.576 17.271l-5.11-2.787a3.5 3.5 0 1 1 0-4.968l5.11-2.787a3.5 3.5 0 1 1 .958 1.755l-5.11 2.787a3.514 3.514 0 0 1 0 1.458l5.11 2.787a3.5 3.5 0 1 1-.958 1.755z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-timer::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M17.618 5.968l1.453-1.453 1.414 1.414-1.453 1.453a9 9 0 1 1-1.414-1.414zM11 8v6h2V8h-2zM8 1h8v2H8V1z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-user::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zM6.023 15.416C7.491 17.606 9.695 19 12.16 19c2.464 0 4.669-1.393 6.136-3.584A8.968 8.968 0 0 0 12.16 13a8.968 8.968 0 0 0-6.137 2.416zM12 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-fire::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 23a7.5 7.5 0 0 1-5.138-12.963C8.204 8.774 11.5 6.5 11 1.5c6 4 9 8 3 14 1 0 2.5 0 5-2.47.27.773.5 1.604.5 2.47A7.5 7.5 0 0 1 12 23z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-bulb::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11 18H7.941c-.297-1.273-1.637-2.314-2.187-3a8 8 0 1 1 12.49.002c-.55.685-1.888 1.726-2.185 2.998H13v-5h-2v5zm5 2v1a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2v-1h8z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-temp::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M8 5a4 4 0 1 1 8 0v5.255a7 7 0 1 1-8 0V5zm1.144 6.895a5 5 0 1 0 5.712 0L14 11.298V5a2 2 0 1 0-4 0v6.298l-.856.597zM8 16h8a4 4 0 1 1-8 0z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}
.cs-icon.ic-earth::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm6.355-6.048v-.105c0-.922 0-1.343-.652-1.716a7.374 7.374 0 0 0-.645-.325c-.367-.167-.61-.276-.938-.756a12.014 12.014 0 0 1-.116-.172c-.345-.525-.594-.903-1.542-.753-1.865.296-2.003.624-2.085 1.178l-.013.091c-.121.81-.143 1.082.195 1.437 1.265 1.327 2.023 2.284 2.253 2.844.112.273.4 1.1.202 1.918a8.185 8.185 0 0 0 3.151-2.237c.11-.374.19-.84.19-1.404zM12 3.833c-2.317 0-4.41.966-5.896 2.516.177.123.331.296.437.534.204.457.204.928.204 1.345 0 .328 0 .64.105.865.144.308.766.44 1.315.554.197.042.399.084.583.135.506.14.898.595 1.211.96.13.151.323.374.42.43.05-.036.211-.211.29-.498.062-.22.044-.414-.045-.52-.56-.66-.529-1.93-.356-2.399.272-.739 1.122-.684 1.744-.644.232.015.45.03.614.009.622-.078.814-1.025.949-1.21.292-.4 1.186-1.003 1.74-1.375A8.138 8.138 0 0 0 12 3.833z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}



.ck-style-grid__button__preview blockquote {
  text-indent: -9999px;
  color: #fff;
}


.ck-style-grid__button__preview .cs-square,
.ck-style-grid__button__preview .cs-rounded,
.ck-style-grid__button__preview .cs-rounded-full {
  border: 2px solid #000;
  border-radius: 5px;
  border-radius: var(--radius);
}
.ck-style-grid__button__preview .cs-rounded {
  border-radius: 4px;
}
.ck-style-grid__button__preview .cs-color {
  background: rgb(28, 41, 61);
  background: rgb(var(--color));
}
.ck-style-grid__button__preview .cs-icon::before {
  top: -12px;
  left: 0;
}
.ck-style-grid__button__preview .cs-definition {
  background: rgba(0, 66, 237, 0.1);
}
.ck-style-grid__button__preview .cs-definition,
.ck-style-grid__button__preview .cs-definition-2,
.ck-style-grid__button__preview .cs-definition-3 {
  min-height: 20px;
  padding: 7px;
}


abbr[title] {
  position: relative;
  cursor: default;
  text-decoration: none;
  border-bottom: 1px dotted #000;
}

abbr[title]::before {
  content: attr(title);
  display: none;
  position: absolute;
  bottom: calc(-100% - 15px);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 3px 5px;
  font-size: .9em;
  font-weight: bold;
  border-radius: 3px;
  color: #fff;
  background: #000;
  white-space: nowrap;
}

abbr[title]::after {
  content: "";
  display: none;
  position: absolute;
  bottom: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: rgba(0,0,0,0) rgba(0,0,0,0) #000 rgba(0,0,0,0);
}

abbr[title]:hover::before, 
abbr[title]:hover::after {
  display: block;
}



.palette-block {
  display: none;
  position: absolute;
  left: 70px;
  background: rgba(0,0,0,.9);
  padding: .25rem 0;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 6rem;
  border-radius: 6px;
}

.mobile-navigation .palette-block {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  bottom: -10px;
  left: initial;
}

.mobile-navigation .palette-block .palette {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.palette-button {
  background-image: url("data:image/svg+xml,%3Csvg fill='%23fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 2c5.522 0 10 3.978 10 8.889a5.558 5.558 0 0 1-5.556 5.555h-1.966c-.922 0-1.667.745-1.667 1.667 0 .422.167.811.422 1.1.267.3.434.689.434 1.122C13.667 21.256 12.9 22 12 22 6.478 22 2 17.522 2 12S6.478 2 12 2zm-1.189 16.111a3.664 3.664 0 0 1 3.667-3.667h1.966A3.558 3.558 0 0 0 20 10.89C20 7.139 16.468 4 12 4a8 8 0 0 0-.676 15.972 3.648 3.648 0 0 1-.513-1.86zM7.5 12a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm9 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM12 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px;
}

.mobile-navigation .palette-button {
  background-size: 16px;
}

.palette-button.show .palette-block {
  display: flex;
}

.palette-block::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 5px 0;
  border-color: transparent rgba(0,0,0,.9)transparent transparent;
}

.palette-block .palette {
  width: 30px;
  height: 30px;
  display: block;
  background: transparent;
  border-radius: 5px;
  border:none;
  cursor: pointer;
  position: relative;
  transition: all .25s;
  margin: .25rem;
}

.palette-block .palette:hover {
  opacity: .9;
}
.palette-block .palette.active {
  box-shadow: 0px 5px 5px -5px;
  border: 3px solid rgba(0,0,0,.1);
}
.palette-block .palette.active::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  display: block;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z'/%3E%3C/svg%3E") no-repeat center;
}

:root {
  --palette-opacity: 1;
}

.dashboard .content {
  --palette-opacity: .5;
}

.dashboard .content[color='#FFFFFF'],
.palette-block > .palette[color='#FFFFFF'] {
  background: rgba(255,255,255, 1);
}
.dashboard .content[color='#F2EFD7'],
.palette-block > .palette[color='#F2EFD7'] {
  background: rgba(242, 239, 215, 1);
  background: rgba(242, 239, 215, var(--palette-opacity));
}
.dashboard .content[color='#FFDFDF'],
.palette-block > .palette[color='#FFDFDF'] {
  background: rgba(255, 204, 153, 1);
  background: rgba(255, 204, 153, var(--palette-opacity));
}
.dashboard .content[color='#FFDFDF'],
.palette-block > .palette[color='#FFDFDF'] {
  background: rgba(255, 223, 223, 1);
  background: rgba(255, 223, 223, var(--palette-opacity));
}
.dashboard .content[color='#F5D9F5'],
.palette-block > .palette[color='#F5D9F5'] {
  background: rgba(245, 217, 245, 1);
  background: rgba(245, 217, 245, var(--palette-opacity));
}
.dashboard .content[color='#CFCFFF'],
.palette-block > .palette[color='#CFCFFF'] {
  background: rgba(231, 231, 255, 1);
  background: rgba(231, 231, 255, var(--palette-opacity));
}
.dashboard .content[color='#CFFFCF'],
.palette-block > .palette[color='#CFFFCF'] {
  background: rgba(231, 255, 231, 1);
  background: rgba(231, 255, 231, var(--palette-opacity));
}
.dashboard .content[color='#DFE6EC'],
.palette-block > .palette[color='#DFE6EC'] {
  background: rgba(223, 230, 236, 1);
  background: rgba(223, 230, 236, var(--palette-opacity));
}
.dashboard .content[color='#EEEDE1'],
.palette-block > .palette[color='#EEEDE1'] {
  background: rgba(238,237,225, 1);
  background: rgba(238,237,225, var(--palette-opacity));
}
.dashboard .content[color='#E7E7E7'],
.palette-block > .palette[color='#E7E7E7'] {
  background: rgba(231, 231, 231, 1);
  background: rgba(231, 231, 231, var(--palette-opacity));
}

